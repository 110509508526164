import { fetchData } from "utils";
import { JSON_HEADER } from "constants/index";

const eventRequest = async (request) => {
  let URL = "https://dw.glowroad.com/datawarehouse/sendEventData";
  try {
    let res = await fetchData(URL, {
      headers: JSON_HEADER,
      method: "POST",
      body: JSON.stringify(request),
    });

    return res;
  } catch (error) {
    console.log(error);
  }
}

export const sendEvent = (eventName,eventId,eventType,otherprop1Key,otherprop1Value,otherprop2Key,otherprop2Value) => {
  let id = window.localStorage.getItem('uuid');
  let request = {
    clientId: "GRReactApp",
    userId: id,
    deviceId: id,
    eventList: [
      {
        eventId,
        eventName,
        eventTime: new Date(),
        mainEventProperties: {
          eventType,
        },
        sessionId: id
      }
    ]
  }
  const {eventList} = request;
  const {mainEventProperties} = eventList[0];
  if(otherprop1Key && otherprop1Value) {
    mainEventProperties.otherprop1Key = otherprop1Key;
    mainEventProperties.otherprop1Value = otherprop1Value;
  }
  if(otherprop2Key && otherprop2Value) {
    mainEventProperties.otherprop2Key = otherprop2Key;
    mainEventProperties.otherprop2Value = otherprop2Value;
  }
  if(window.location.origin === "https://glowroad.com" || window.location.origin === "https://www.glowroad.com") {
    eventRequest(request).then(response => {
      console.log(response);
    });
  }
}