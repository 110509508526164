import {
  address,
  emailCheck,
  mobileCheck,
  nameCheck,
  pinCheck,
  required,
  password,
  otpCheck,
  yTLinkCheck,
  timeFormatCheck,
  cloudFrontLinkCheck,
  floatCheck,
  integerCheck
} from './regex';
  
  export const validateRequired = (value = '') => !required.test(value.trim());
  
  export const validateName = (value = '') =>
    !required.test(value.trim()) && nameCheck.test(value.trim());
  export const validatePassword = (value= '') => password.test(value.trim());
  export const validateMobile = (value = '') => mobileCheck.test(value.trim());

  export const validatePin = (value = '') => pinCheck.test(value.trim());
  
  export const validateEmail = (value = '') => emailCheck.test(value.trim());
  
  export const validateAddress = (value = '') => address.test(value.trim());

  export const validateOtp = (value= '') => otpCheck.test(value.trim());

  export const validateWeight = (value='') => {if(value <= 5) { return true} else {return false}};

  export const validatePpdDiscount = (val) => {
    return (!val || (integerCheck.test(val) && val >= 0));
  }

  export const validateUserNameInPassword = (password= '',email = '') => {
    const username = email.split('@')[0];
    return !password.includes(username);
  }

  export const validateCloudFrontLink = (link = '') => cloudFrontLinkCheck.test(link);

  export const validateYTLink = (link = '') => yTLinkCheck.test(link);

  export const validateTime = (time = '') => timeFormatCheck.test(time);

  export const validateFloat = (number = '') => floatCheck.test(number);

  export const validateInteger = (number = '') =>  integerCheck.test(number);
  