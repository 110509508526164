import { SET_PRODUCT_DATA } from 'constants/store';


const initialProductState = {
  productData: {
    countryCode: "IN",
		productAttributes: {},
		dimensionAttributes: {},
		productInventoryMap: {},
		multiImage: [],
  }
}

const productReducer = (state = initialProductState, action) => {
  switch (action.type) {
    case SET_PRODUCT_DATA:
      return {
        ...state,
        productData: action.productData,
      };
    default:
      return state;
  }
}

export default productReducer;