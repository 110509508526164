import styled from "styled-components";
import { Button } from "../../styled-components/buttons";
import { Flex, MediaQL, MediaQ } from "../../styled-components/utils";

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  ${Flex("row nowrap", "center", "center")};
  z-index: 2001;
  ${MediaQL("600px")`
      overflow-y: auto;
      min-height: 100%;
      max-height: 850px;
    `};
`;

export const ModalDialog = styled.div`
  width: 550px;
  margin: 0 auto;
  height: 700px;
  position: relative;
  margin-top: 0;

  ${MediaQ("768px")`
        width: 800px;
        height: 550px;
    `};
  ${MediaQL("600px")`
      align-self: flex-start;
      margin-top: 50px;
    `};
`;

export const CloseButton = styled(Button)`
  min-width: 48px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  background: transparent;
  border: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0;
  z-index: 10000;
  ${Flex("row nowrap", "center", "center")};
`;

export const Icon = styled.img`
  height: 1rem;
  width: 1rem;
  vertical-align: middle;
  margin: 0;
  padding: 0;
`;
