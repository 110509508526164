import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Flex } from '../../styled-components/utils';

export const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: transparent;
    ${Flex('row nowrap', 'center', 'center')};
    border: none;
`;

const LoadingCircle = styled.div`
    border: 6px solid #eee;
    border-top: 6px solid ${props => props.theme.colors.primary};
    animation: ${spin} 1.25s linear forwards infinite;
    height: 4rem;
    width: 4rem;
    border-radius: 100%;
`;

const Loader = props => {
    return (
        <Wrapper>
            <LoadingCircle />
        </Wrapper>
    );
};

export default Loader;
