import { css, keyframes } from "styled-components";

export const Flex = (flow = "row nowrap", ai = "center", jc = "flex-start") => {
  return css`
    display: flex;
    flex-flow: ${flow};
    align-items: ${ai};
    justify-content: ${jc};
  `;
};

export const MediaQ = (breakPoint) => {
  return (...args) => {
    return css`
      @media screen and (min-width: ${breakPoint}) {
        ${css(...args)};
      }
    `;
  };
};

export const MediaQL = (breakPoint) => {
  return (...args) => {
    return css`
      @media screen and (orientation: landscape) and (min-width: ${breakPoint}) {
        ${css(...args)};
      }
    `;
  };
};

export const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
      transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const getCollectionProductRedirectionLink = (objectId) => {
  let obj = JSON.stringify({ objectId, appRedirectUrl: "collectionProduct" });
  let base64String = btoa(obj);
  if (base64String) {
    return `/inApp/openLink?redirect=${base64String}`;
  }
  return undefined;
};

export const getCollectionShareLink = (shareMessage, shareImage) => {
  if (shareMessage && shareImage) {
    return `/inApp/shareMore?shareMessage=${shareMessage}&shareImage=${shareImage}`;
  }

  return undefined;
};
