const hindiLocale = {
  RECHARGE_STATUS: 'रिचार्ज स्टेटस',
  AWAITING_RESPONSE: 'भुगतान का स्टेटस कंफर्म किया जा रहा है',
  DELETE_ACCOUNT: 'अकाउंट डिलीट करें',
  DELETE_ACCOUNT_QUESTION: 'आप अपना अकाउंट क्यों डिलीट करना चाहेंगे?',
  DELETE_ACCOUNT_REASONS: [
    { text: 'मुझे अब GlowRoad का इस्तेमाल नहीं करना है' },
    { text: 'मैं एक अलग अकाउंट इस्तेमाल कर रहा हूँ' },
    { text: 'मुझे नया अकाउंट बनाना है' },
    { text: 'मुझे निजता से जुड़ी चिंताएं हैं' },
    { text: 'अन्य' },
  ],
  DELETE_ACCOUNT_CONTINUE: 'आगे बढ़ें',
  DELETE_ACCOUNT_CONSENT_HEADING: 'आप अपना अकाउंट डिलीट करने वाले हैं।',
  DELETE_ACCOUNT_CONSENT_MAIN:
    'आप अपने GlowRoad खाते को स्थायी रूप से हटाने और अपना डेटा मिटाने के लिए हमारे पास अनुरोध सबमिट करने वाले हैं। इससे जुड़े सभी डेटा (आपकी प्रोफ़ाइल, फ़ोटो, समीक्षाएं और सदस्यताएं शामिल हैं) 40 दिनों में स्थायी रूप से हटा दिए जाएंगे। अकाउंट डिलीट हो जाने के बाद यह जानकारी फिर से हासिल नहीं की जा सकती।',
  DELETE_ACCOUNT_CONSENT_BLOCK:
    'कृपया ध्यान दें कि अपना अकाउंट डिलीट करना एक स्थायी कार्रवाई है। एक बार आपका अकाउंट डिलीट हो जाने के बाद यह आपके लिए उपलब्ध नहीं रहेगा और इसे रीस्टोर नहीं किया जा सकता है। अगर आप बाद में फ़ैसला करते हैं कि आप हमसे फिर से ऑर्डर देना शुरू करना चाहते हैं या आप ऐसे प्रॉडक्ट और सेवाओं का इस्तेमाल करना चाहते हैं जिनके लिए अकाउंट की ज़रूरत है, तो आपको एक नया अकाउंट बनाना होगा।',
  DELETE_ACCOUNT_CONSENT_NOTE:
    'कृपया ध्यान रखें कि GlowRoad के लिए कुछ प्रकार के डेटा को बनाए रखना कानूनी रूप से आवश्यक है, जैसे कि ऑर्डर का इतिहास। हम यह टैक्स और अकाउंटिंग और धोखाधड़ी से बचाव के उद्देश्यों सहित लागू कानूनों के अनुसार करते हैं।',
  DELETE_ACCOUNT_CONSENT_CONFIRM:
    'हाँ, मुझे अपना GlowRoad अकाउंट हमेशा के लिए डिलीट करना है और अपना डेटा डिलीट करना है।',
  DELETE_ACCOUNT_DELETE_MY_ACCOUNT: 'मेरा अकाउंट डिलीट कर दें',
};

export default hindiLocale;
