import styled from 'styled-components';

export const Button = styled.button`
    border: none;
    ${props => {
        if (props.color) {
            return props.color === 'primary'
                ? `background: ${props.theme.colors.primary}`
                : 'background: none';
        }
    }};
    color: white;
    font-size: ${props => (props.size === 'large' ? '1.25rem' : '0.88rem')};
    word-spacing: ${props => (props.size === 'large' ? '3px' : '2px')};
    letter-spacing: ${props => (props.size === 'large' ? '0.75px' : '0.2px')}
    height: ${props =>
        props.size && props.size === 'large' ? '3rem' : '36px'};
    line-height: height: ${props =>
        props.size && props.size === 'large' ? '3rem' : '36px'};
    min-width: ${props => (props.size === 'large' ? '120px' : '88px')};
    display: block;
    cursor: pointer;
    font-weight: 700;
    padding: 0 1rem;
    text-align: center;
    border-radius: ${props => (props.radius ? props.radius : '25px')};
`;

export const ButtonRounded = styled(Button)``;

export const ButtonLarge = styled(Button)`
    border-radius: 0;
    ${props => {
        if (props.color) {
            return props.color === 'primary'
                ? props.theme.colors.primary
                : 'none';
        }
    }};
`;

export const ButtonLink = styled(Button)`
    background: none;
    border: none;
    color: ${props => props.theme.colors.primary};
    text-transform: uppercase;
    letter-spacing: 0.3px;
    margin: 2rem 0 0;
    padding: 0;
    font-size: 0.9rem;
`;

export const SubmitButton = styled(Button)`
    &:disabled {
        background: #ccc;
        color: white;
    }
`;
