import styled from 'styled-components';
import { MediaQ } from '../../../../styled-components/utils';

export const Wrapper = styled.div`
    .slick-slide,
    .slick-slider .slick-track,
    .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);

        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);

        -webkit-perspective: 1000;
        -moz-perspective: 1000;
        -ms-perspective: 1000;
        perspective: 1000;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    .slick-dots {
        bottom: -28px;
        display: none !important;
    }
    .slick-list {
        height: 100%;
    }
    .slick-track {
        height: 100%;
    }
    .slick-slide > div {
        padding: 1rem 0;
    }
    .slick-slide > div > div {
        height: 100%;
        width: 85%;
        margin: 0 auto;
    }
    .slick-dots li {
        width: 24px;
        height: 24px;
        margin: 0 4px;
    }
    .slick-dots li button:before {
        content: '';
        height: 0;
        width: 0;
        border: 8px solid #000;
        border-radius: 50%;
        top: 3px;
        left: 3px;
    }
    .slick-prev,
    .slick-next {
        font-size: 15px !important;
    }
    .slick-arrow {
        display: block;
        z-index: 500;
    }
    .slick-arrow:hover > img {
        opacity: 0.9;
    }
    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 0;
    }
    .slick-prev:before,
    .slick-next:before {
        content: '' !important;
    }
    .slick-dots li button::before {
        border: 8px solid rgba(0, 0, 0, 0.125);
        opacity: 1;
    }
    .slick-dots li.slick-active button: : before {
        border: 8px solid ${props => props.theme.colors.primary};
    }

    ${MediaQ('768px')`
        .slick-slide > div > div {
            height: 100%;
            width: 85%;
            margin: 0 auto;
        }
        
        .slick-arrow {
            height: 24px;
            width: 24px;
            cursor: pointer;
            z-index: 25;
            display: block !important;
        }
        .slick-dots {
            bottom: -8px;
            display: block !important;
        }
        .slick-prev {
            left: 0px;
        }
        .slick-next {
            right: 0px;
        }
    `};
    ${MediaQ('768px')`
        .slick-dots {
            bottom: -24px;
        }
    `}
`;

export const Icon = styled.img`
    height: 24px;
    width: auto;
    transform: ${props =>
        props.direction === 'next' ? 'rotate(0deg)' : 'rotate(180deg)'};
    color: #000;
    opacity: 0.5;
    margin: auto;
`;
