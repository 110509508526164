import { getEncodedMetaLink } from "../utils";

export const supplierBenefitsData = [
  {
    id: "0201",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/supplier-onboarding/Payouts.png",
    title: "Zero Commission",
    text: ["Suppliers selling on Glowroad keep 100% of their profit. You do not have to pay any commission."],
    type: "normal",
  },
  {
    id: "0202",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/supplier-onboarding/Logistics.png",
    title: "Logistics Support",
    text: [
      "We provide complete logistic support through the best courier partners at lowest prices.",
    ],
    type: "normal",
  },
  {
    id: "0203",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/supplier-onboarding/Partnerships.png",
    title: "Timely and Transparent Payouts",
    text: [
      "Funds will be deposited in your bank account every day.",
    ],
    type: "normal",
  },
  {
    id: "0204",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/supplier-onboarding/product-shoots.png",
    title: "No special product shoots needed",
    text: [
      "We prefer flat shoots, so you do not have to worry about fancy product shoots.",
    ],
    type: "normal",
  },
  {
    id: "0205",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/supplier-onboarding/policy.png",
    title: "Fair Supplier Policies",
    text: [
      "Our supplier policy has no hidden or unreasonable clauses or expectations.",
    ],
    type: "normal",
  },
  {
    id: "0206",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/supplier-onboarding/supplier-support.png",
    title: "Excellent Supplier Support",
    text: [
      "A dedicated team will be always available to solve issues, if you have any.",
    ],
    type: "normal",
  },
];

export const testimonialsData = [
  {
    id: "0216",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/supplier-onboarding/t6-rishabh.jpeg",
    name: "Rishabh Gupta, Delhi",
    text: [
      "It has been a tremendous year for us at Glowroad.com. We started our business association in 2018 and  have seen 100% Y-o-Y growth on the platform. With 1.5K orders/month, GR contributes 15% of our daily sales. With our manufacturing expertise and GR's market penetration and easy of doing business we expect it to grow 3 fold in current fiscal year.",
    ],
  },
  {
    id: "0211",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/supplier-onboarding/t1-alpa.jpg",
    name: "Alpa Lakkad, Surat",
    text: [
      "GlowRoad has given me tremendous growth and the staff has been very supportive. My order flows have scaled up by 5 times from past 3 months. The delivery of products has been fast. Payments have been very smooth. I have recommended GlowRoad to other sellers as well.",
    ],
  },
  {
    id: "0212",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/supplier-onboarding/t3-sachin.jpg",
    name: "Sachin, Surat",
    text: [
      "My overall business has grown up by 2.5 times because of GlowRoad. GlowRoad team promotes my products in a convenient way. Their process is very standardized and they have a professional team. They go an extra step to solve my queries in time. All in all, I am very satisfied & content to work on GlowRoad.",
    ],
  },
  {
    id: "0217",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/supplier-onboarding/t7-uzzawal.jpg",
    name: "Uzzawal, Paniipat",
    text: [
      "As a 50 year old manufacture of home furnishing our company is always trying to get connected with abundance reseller so that we can serve the pan India audience which is not possible with physical stores. And through great platform like Glowroad we are able to achieve this at much reliable and faster rate than brick & motor stores.",
    ],
  },
  {
    id: "0213",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/supplier-onboarding/t4-santosh.jpg",
    name: "Santosh, Shantipur",
    text: [
      "I joined Glowroad in the month of Feb 2019 and my journey & growth has been remarkable.I aspire to be the No 1 supplier of Sarees in the coming months with quality products and unique variety. Glowroad has helped me scale my business further and also strengthen my trust in the online commerce . I look forward to more success stories with Glowroad.",
    ],
  },
  {
    id: "0214",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/supplier-onboarding/t5-sidharth.jpg",
    name: "Siddharth Jain, New Delhi",
    text: [
      "Its been Around 2 months I am working on Glowroad. My experience with Glowroad is quite good and smooth .Glowroad Have a nice seller panel , clear payments and best of all a Responsive Team. I Get all support from the team as such my sales grew by 100 % within a month. I Suggest Glowroad to sellers as well as Re- sellers as they work to get the best price.",
    ],
  },
  {
    id: "0215",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/supplier-onboarding/t2-zuo.jpg",
    name: "Zuo Libin, Bangalore",
    text: [
      "I have been a seller on Glowroad from March. Glowroad staffs have been very supportive & my sales are increased day by day. The delivery is very fast. Mode of payments is very smooth. Uploading, promotions, sales, order processing is all user friendly. I will recommend new sellers to join Glowroad to develop their business.",
    ],
  },
];

export const videoData = [
  {
    id: 0,
    title: "Hindi",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/hindi.png",
    video: "https://www.youtube.com/embed/Vi5WyY7-CgM?autoplay=1&fs=1&rel=0",
  },
  {
    id: 1,
    title: "English",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/english.jpg",
    video: "https://www.youtube.com/embed/g3qcyRtQ3TE?autoplay=1&fs=1&rel=0",
  },
  {
    id: 2,
    title: "Telugu",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/telugu.jpg",
    video: "https://www.youtube.com/embed/UAgnDM6b0XA?autoplay=1&fs=1&rel=0",
  },
  {
    id: 3,
    title: "Kannada",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/kannada.jpg",
    video: "https://www.youtube.com/embed/NX9j1Izw_gw?autoplay=1&fs=1&rel=0",
  },
  {
    id: 4,
    title: "Bangla",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/bangla.jpg",
    video: "https://www.youtube.com/embed/BcxWv3hAioQ?autoplay=1&fs=1&rel=0",
  },
  {
    id: 5,
    title: "Marathi",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/marathi.jpg",
    video: "https://www.youtube.com/embed/_D9v7UpJSZk?autoplay=1&fs=1&rel=0",
  },
];

export const stepsData = [
  {
    id: "01",
    title: "Step 01",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/image1.png",
    text: "Find awesome products to sell at Wholesale Price",
  },
  {
    id: "02",
    title: "Step 02",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/image2.png",
    text: "You add your own margin",
  },
  {
    id: "03",
    title: "Step 03",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/image3.png",
    text: "Share the product on WhatsApp and Facebook",
  },
  {
    id: "04",
    title: "Step 04",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/image4.png",
    text: "Customer buys the product from your shop",
  },
  {
    id: "05",
    title: "Step 05",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/image5.png",
    text:
      "We deliver the product and collect COD. Your profit is deposited in your bank account.",
  },
];

export const homeTestimonialData = [
  {
    id: "01",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/user3.png",
    name: "Remya",
    place: "Kolam, Kerala",
    text:
      "I earn around INR 15,000 per month with the help of super easy to use App GlowRoad",
  },
  {
    id: "02",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/user5.png",
    name: "Neetika Arora",
    place: "Faridabad",
    text:
      "Post marriage and baby I got an option to kick start my career once again. GlowRoad has got good Quality in reasonable price products. GlowRoad has brought magic in my life",
  },
  {
    id: "03",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/user2.png",
    name: "Reshma Sandeep Maurya",
    place: "Mumbai",
    text:
      "From a house wife I am an entrepreneur now. In love with My Shop Prime option which gives me my own website",
  },
  {
    id: "04",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/user4.png",
    name: "Rama Dash",
    place: "Bhubaneswar, Odisha",
    text:
      "Along with my job I resell through GlowRoad. It has got Good Quality Product with great service",
  },
  {
    id: "05",
    icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/user1.png",
    name: "Ahmad Zahid",
    place: "Kashmir",
    text:
      "I am able to earn while studying and best part of GlowRoad is Cash On Delivery,Easy Return Policy and Payment on time",
  },
];

export const aboutTimelineData = [
  {
    id: "0301",
    details: "HealthcareMagic Founded",
    year: "2008",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/healthcare-magic.svg",
    color: "#3B7F8C",
  },
  {
    id: "0302",
    details: "HealthcareMagic was acquired by EBIX, a US public listed company",
    year: "2014",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/healthcare-magic.svg",
    color: "#0A5F6F",
  },
  {
    id: "0303",
    details: "GlowRoad Founded and Funded",
    year: "2017",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/glowroad-logo.svg",
    color: "#084753",
  },
  {
    id: "0304",
    details: "GlowRoad Receives Second Round of Funding",
    year: "2019",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/glowroad-logo.svg",
    color: "#03181C",
  },
];

const sonalsTimeline = aboutTimelineData;

const commonTimeline = [
  {
    id: "0305",
    details: "TechUnified was acquired by ORG, an Indian public listed company",
    year: "2007",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/techunified.jpg",
    color: "#6C9FA9",
  },
  ...aboutTimelineData,
];
const nileshsTimline = [
  {
    id: "0306",
    details: "Tech Lead at Infibeam",
    year: "2011",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/infibeam.png",
    color: "#6C9FA9",
  },
  ...commonTimeline.filter((tl) => tl.id !== "0305" && tl.id !== "0301"),
];

export const aboutFoundersData = [
  {
    id: "0311",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/sonal-new.png",
    designation: "Founder",
    name: "Dr. Sonal, MD",
    details: [
      "Dr. Sonal is a doctor by training and entrepreneur extraordinaire by passion. A mother of two, she took a career break to raise them and during this time met a lot of like-minded enterprising women who felt there was a lack of flexible earning opportunities for women. The idea germinated and GlowRoad was born, which is now a 6 Million+ strong community of women & men resellers spread across 2000+ cities",
      "Prior to GlowRoad, she founded HealthcareMagic, which started with a small team to a global network of 18,000+ specialists, answering health questions from individuals at the rate of 146 answers per hour across 74+ specialities! The company was acquired by EBIX and is now a US public listed company.",
    ],
    timeline: sonalsTimeline,
    link: "https://www.linkedin.com/in/dr-sonal-verma-49872199/",
  },
  {
    id: "0312",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/kunal.jpg",
    designation: "Co-Founder",
    name: "Kunal",
    details: [
      "Kunal is the strategic thinker behind GlowRoad and believes in the network effect of the GlowRoad community. Under his astute leadership, the company has made rapid strides and is now growing at a blazing pace.",
      "An engineer from IIT Kanpur, Kunal is a serial entrepreneur and GlowRoad is his third venture. Prior to this, he founded diverse companies like TechUnified, an IT services provider and HealthcareMagic, an online medical consultation platform. Both had successful exits to public listed entities in India and US.",
    ],
    timeline: commonTimeline,
    link: "https://www.linkedin.com/in/kunal-sinha-26206a4/",
  },
  {
    id: "0313",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/Nitesh.jpg",
    designation: "Co-Founder",
    name: "Nitesh",
    details: [
      "Nitesh is the operational brain behind GlowRoad and is instrumental in improving the organizational processes. Apart from being involved in enhancing quality, productivity, and efficiency, he also ensures that GlowRoad attracts the right talent and has top-notch hiring procedures. He has great financial and compliance acumen which ensures GlowRoad moves on a sustainable growth path.",
      "Prior to this, he co-founded HealthcareMagic and led business development efforts for TechUnified across South-East Asia.",
    ],
    timeline: commonTimeline,
    link: "https://www.linkedin.com/in/nitesh-pant-936a612/",
  },
  {
    id: "0313",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/shaker.jpg",
    designation: "Co-Founder",
    name: "Shekhar",
    details: [
      "Shekhar is a multifaceted individual and a co-founder at GlowRoad. He is a tech enthusiast and also has extensive insights into consumer behavior patterns. He’s on top of all things UI/UX and is also leading new initiatives at GlowRoad. Shekhar is also a member of the advisory council at Harvard Business Review where he regularly contributes with health-tech insights. A prolific networker, he loves talking to and advising start-ups and is an angel investor in a personal capacity.",
      "Prior to this, he co-founded HealthcareMagic. Under his stewardship, HealthcareMagic became the most followed lifestyle/health brand on the social-web globally with its twitter & facebook handles having close to 3 million followers each.",
    ],
    timeline: commonTimeline,
    link: "https://www.linkedin.com/in/shekharsahu/",
  },
  {
    id: "0313",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/nilesh-1.jpg",
    designation: "Co-Founder",
    name: "Nilesh",
    details: [
      "Nilesh is a problem solver, growth hacker and a co-founder at GlowRoad. He’s a techie by profession and an entrepreneur at heart. This unique blend coupled with design thinking abilities enables him to look through the prism of desirability, feasibility and viability to create effective technology solutions.",
      "An alumnus of IIT-Bombay, Nilesh can be found interacting with members of all business functions to draw insights and deliver results.",
      "Prior to this Nilesh was a product architect at HealthcareMagic and has solid e-commerce technology experience with his stints in Infibeam and Future Bazaar.",
    ],
    timeline: nileshsTimline,
    link: "https://www.linkedin.com/in/nileshpadariya/",
  },
];

export const teamMembersData = [
  {
    designation: "Head - Business Operations",
    id: "0321",
    name: "Amitpal Singh",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/team-photo/amit.jpeg",
    socialLink: "https://www.linkedin.com/in/amitpal-singh-bhatia-1b1a86a0/",
  },
  {
    designation: "Head - Engineering",
    id: "0325",
    name: "Kumar Saurabh",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/team-photo/kumarsaurabh.jpeg",
    socialLink: "https://www.linkedin.com/in/kumrsaurabh/",
  },
  {
    designation: "Head - Analytics",
    id: "0328",
    name: "Pritish Ekka",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/team-photo/pritish.jpeg",
    socialLink: "https://www.linkedin.com/in/iampritishekka/",
  },
];

export const counterData = [
  {
    id: "0341",
    text: "Resellers",
    number: "8 Million",
  },
  {
    id: "0342",
    text: "Sellers",
    number: "53K+",
  },
  {
    id: "0343",
    text: "Buyers",
    number: "19M+",
  },
  {
    id: "0344",
    text: "Cities",
    number: "2K+",
  },
];

export const carouselData = [
  {
    id: "0351",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/gallery/galleryNew-1.jpg",
  },
  {
    id: "0352",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/gallery/galleryNew-2.jpg",
  },
  {
    id: "0353",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/gallery/galleryNew-3.jpg",
  },
  {
    id: "0354",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/gallery/galleryNew-4.jpg",
  },
  {
    id: "0355",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/gallery/galleryNew-5.jpg",
  },
  {
    id: "0356",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/gallery/galleryNew-6.jpg",
  },
];

export const newsData = [
  {
    id: "0361",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/news-articles/economic-times.png",
    organization: "The Economic Times",
    type: "link",
    link:
      "https://economictimes.indiatimes.com/tech/technology/amazon-acquires-reseller-platform-glowroad-to-bolster-social-commerce-ambitions/articleshow/90987418.cms?from=mdr",
  },
  {
    id: "0365",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/news-articles/amazon.png",
    organization: "Amazon",
    type: "link",
    link:
      "https://www.aboutamazon.in/news/amazon-india-news/excited-to-bring-glowroad-to-amazon",
  },
  {
    id: "0366",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/news-articles/business-standard.png",
    organization: "Business Standard",
    type: "link",
    link:
      "https://www.business-standard.com/article/companies/e-commerce-giant-amazon-acquires-indian-social-commerce-startup-glowroad-122042200298_1.html",
  },
  {
    id: "0366",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/news-articles/ani.png",
    organization: "ANI",
    type: "link",
    link:
      "https://www.aninews.in/news/business/business/glowroad-announces-shubh-aarambh-sale-to-start-on-september-23-202220220923112131/",
  },
];

export const hiringData = [
  {
    id: "0376",
    category: "Technology",
    roles: [
      {
        id: "030571",
        role: "Senior Software Engineer (3-5 yrs)",
        link:
          "https://angel.co/company/local-queen/jobs/560766-senior-software-engineer-3-5-yrs",
      },
    ],
  },
  {
    id: "0371",
    category: "Design",
    roles: [
      {
        id: "030171",
        role: "Video Editor (2-5 yrs)",
        link:
          "https://angel.co/company/local-queen/jobs/546896-video-editor-2-5-yrs",
      },
    ],
  },
  {
    id: "0372",
    category: "Sales",
    roles: [
      {
        id: "030172",
        role: "Lead Category Manager - IIT/IIM/ISB (2- 4 Yrs)",
        link:
          "https://angel.co/company/local-queen/jobs/548567-lead-category-manager-iit-iim-isb-2-4-yrs",
      },
      {
        id: "030272",
        role: "Senior Business Development Manager - (3-6 Yrs )",
        link:
          "https://angel.co/company/local-queen/jobs/558558-senior-business-development-manager-3-6-yrs",
      },
    ],
  },
  {
    id: "0373",
    category: "Marketing",
    roles: [
      {
        id: "030173",
        role: "PR/Corporate Communications Manager (4-7 Yrs)",
        link:
          "https://angel.co/company/local-queen/jobs/549045-pr-corporate-communications-manager-4-7-yrs",
      },
      {
        id: "030273",
        role: "Social Media Marketing Manager - (3-5 yrs)",
        link:
          "https://angel.co/company/local-queen/jobs/556528-social-media-marketing-manager-3-5-yrs",
      },
    ],
  },
  {
    id: "0374",
    category: "Operations",
    roles: [
      {
        id: "030174",
        role: "HR Executive",
        link: "https://angel.co/company/local-queen/jobs/550169-hr-executive",
      },
    ],
  },
  {
    id: "0375",
    category: "Others",
    roles: [
      {
        id: "030175",
        role: "Business Analyst - IIT/IIM/ISB (1-3 Yrs)",
        link:
          "https://angel.co/company/local-queen/jobs/546911-business-analyst-iit-iim-isb-1-3-yrs",
      },
      {
        id: "030275",
        role: "(Sr.) Product Manager - IIM/ISB/XLRI (4-7 Yrs)",
        link:
          "https://angel.co/company/local-queen/jobs/546918-sr-product-manager-iim-isb-xlri-4-7-yrs",
      },
      {
        id: "030375",
        role:
          "Assistant Vice President - Strategic Projects IIT/IIM/ISB (2-4 yrs)",
        link:
          "https://angel.co/company/local-queen/jobs/549048-assistant-vice-president-strategic-projects-iit-iim-isb-2-4-yrs",
      },
    ],
  },
];

export const investorsData = [
  {
    id: "0381",
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/investors/accel.png",
    link: "https://www.accel.com/",
    details: ["Investor in Facebook, Slack, Flipkart"],
  },
  {
    id: "0382",
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/investors/cdh.png",
    link: "http://www.cdhfund.com/index.php?m=content&c=index&a=english_index",
    details: ["More than 60+ IPOs"],
  },
  {
    id: "0383",
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/investors/kp.png",
    link: "http://kipvc.com/main.html",
    details: ["More than 150+ IPOs"],
  },
  {
    id: "0385",
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/investors/rb-investments.png",
    link: "https://rbworld.com/About%20us.html",
    details: ["Investors in Swiggy, Homelane and TeaBox"],
  },
  {
    id: "0384",
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/about/investors/vertex.png",
    link: "https://www.vertexventures.com/southeast-asia-india/about/",
    details: ["Investors in Facebook, Quora, Couchbase, Licious"],
  },
];

export const instructionsData = [
  {
    number: "01",
    title: "RECORD A VIDEO",
    text: "Record a video on your mobile. \nTell why do you love GlowRoad",
    bgColor: "#f24965",
  },
  {
    number: "02",
    title: "UPLOAD VIDEO \nON YOUTUBE",
    text: "Go to the YouTube App on your mobile \nand upload your video",
    bgColor: "#efd27b",
  },
  {
    number: "03",
    title: "ADD YOUR \nREFERRAL LINK",
    text:
      "Paste your referral link \non the video description and mention \nUse my referral link to install GlowRoad \napp from playstore & get Rs.200",
    bgColor: "#b4ecde",
  },
  {
    number: "04",
    title: "COPY THE VIDEO \nLINK AND SUBMIT \nYOUR ENTRY",
    text:
      "Once Step 1,2 & 3 are complete, \ncopy your YouTube video link and \nsubmit your entry here",
    bgColor: "#f24965",
  },
];

export const testimonialsContestVideosData = [
  {
    id: 1,
    thumbnail: "https://d1ixo36kppfedg.cloudfront.net/web/images/videoThumbnails/thumbnail-1.jpg",
    video: "https://www.youtube.com/embed/1MsZddnqvAk?rel=0",
    text:
      "GlowRoad is the best reselling app! Check out \nhow by clicking on this video",
    name: "Simran",
    isLoaded: false,
  },
  {
    id: 2,
    thumbnail: "https://d1ixo36kppfedg.cloudfront.net/web/images/videoThumbnails/thumbnail-2.jpg",
    video: "https://www.youtube.com/embed/OQswwEbfbQA?rel=0",
    text:
      "GlowRoad has helped me earn Rs.35000/month \nfrom home. Check out how on my video",
    name: "Neethika",
    isLoaded: false,
  },
];

export const testimonialsContestReviewsData = [
  {
    name: "Arundhati",
    text:
      "Brilliant management, smart work pays off here, I am a regular user in Glowroad, they treat their reseller as a part of the Glowroad community, I am very happy with the process of return and refund.",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/arundhati.jpg",
  },
  {
    name: "Ayushi",
    text:
      "GlowRoad has helped me to start an online business without investment thanks to GlowRoad for solving my issue on time and helping me and my customer.",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/ayushi.jpg",
  },
  {
    name: "Gill",
    text:
      "Awesome experience with the GlowRoad team and I must say a good platform for resellers, I am a satisfied re-seller with GlowRoad and my customers are really happy with the products and prices.",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/gill.jpg",
  },
  {
    name: "Prasham",
    text:
      "I really happy with the team effort, Released my payment on time, I have now confidence when it comes to reselling. Thank you GlowRoad.",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/prasham.jpg",
  },
  {
    name: "Saubarna",
    text:
      "I thank GlowRoad, it helped me continue working after marriage I would like to recommend to every woman or housewife who wants to earn from home.",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/home/saubarna.jpg",
  },
];

export const testimonialsContestTncData = [
  "Contest period is from 29th July’19 to 4th August’19. Submit your entries within these dates",
  "Only 1 entry will be accepted per user",
  "The testimonial video uploaded on YouTube should have at least 100 views",
  "The testimonial video uploaded on Youtube should have at least 50 Likes",
  "10 Winners would win Rs.2000 each in their GlowRoad Wallet",
];

export const operatorData = [
  { value: "Airtel", name: "Airtel" },
  { value: "BSNL", name: "BSNL" },
  { value: "Idea", name: "Idea" },
  { value: "Jio", name: "Jio" },
  { value: "MTNL", name: "MTNL" },
  { value: "Tata Indicom", name: "Tata Indicom" },
  { value: "Tata DOCOMO", name: "Tata DOCOMO" },
  { value: "T24", name: "T24" },
  { value: "Vodafone", name: "Vodafone" },
];

export const circleData = [
  { value: "Kolkata", name: "Kolkata" },
  { value: "Mumbai", name: "Mumbai" },
  { value: "Delhi", name: "Delhi" },
  { value: "Chennai", name: "Chennai" },
  { value: "Maharashtra", name: "Maharashtra" },
  { value: "Gujrat", name: "Gujarat" },
  { value: "Andhra Pradesh", name: "Andhra Pradesh" },
  { value: "Karnataka", name: "Karnataka" },
  { value: "Tamilnadu", name: "Tamil Nadu" },
  { value: "Kerala", name: "Kerala" },
  { value: "Punjab", name: "Punjab" },
  { value: "Haryana", name: "Haryana" },
  { value: "Uttar Pradesh (West)", name: "Uttar Pradesh (West)" },
  { value: "Uttar Pradesh (East)", name: "Uttar Pradesh (East)" },
  { value: "Rajasthan", name: "Rajasthan" },
  { value: "Madhya Pradesh", name: "Madhya Pradesh" },
  { value: "WestBengal & AN Island", name: "West Bengal & AN Island" },
  { value: "Himachal Pradesh", name: "Himachal Pradesh" },
  { value: "Bihar & Jharkhand", name: "Bihar & Jharkhand" },
  { value: "Orissa", name: "Orissa" },
  { value: "Assam", name: "Assam" },
  { value: "North East", name: "North East" },
  { value: "J&K", name: "J&K" },
  { value: "Uttaranchal", name: "Uttaranchal" },
  { value: "All", name: "All" },
];

export const paymentMethodsData = [
  {
    id: 1,
    text: "Pay using Paytm",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/icons/paytm.png",
    name: "paytm",
  },
  {
    id: 2,
    text: "Pay using Credit / Debit Cards and Wallet",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/icons/card.png",
    name: "card",
  },
  {
    id: 3,
    text: "Pay using Netbanking",
    image: "https://d1ixo36kppfedg.cloudfront.net/web/images/icons/netbanking.png",
    name: "netbanking",
  },
];

export const membershipDiscountData = {
  basePrice1: "499",
  offerPrice1: "299",
  basePrice2: "1499",
  offerPrice2: "999",
  timerEndDate: "11-01-2019 00:00:00",
  discountPercentage: "",
};

export const membershipDetailsData = [
  {
    id: 1,
    type: "quarterly",
    amount: "₹299",
    duration: "3 months",
    benefits: [
      {
        image: "https://d1ixo36kppfedg.cloudfront.net/web/images/membership/discount.png",
        text: "FLAT ₹30 Off",
        details: "FLAT ₹30 discount on every product",
      },
      {
        image: "https://d1ixo36kppfedg.cloudfront.net/web/images/membership/exclusive.png",
        text: "15% Wallet Payment",
        details: "Members can pay 15% from wallet",
        moreDetails: "Non members can pay 5% only",
      },
      {
        image: "https://d1ixo36kppfedg.cloudfront.net/web/images/membership/cashback.png",
        text:
          membershipDiscountData.timerEndDate.length > 0
            ? membershipDiscountData.offerPrice1.length > 0
              ? `₹${membershipDiscountData.offerPrice1} Cashback`
              : "₹299 Cashback"
            : "₹299 Cashback",
        details:
          membershipDiscountData.timerEndDate.length > 0
            ? membershipDiscountData.offerPrice1.length > 0
              ? `Instant ₹${membershipDiscountData.offerPrice1} cashback on signup`
              : "Instant ₹299 cashback on signup"
            : "Instant ₹299 cashback on signup",
      },
      {
        image: "https://d1ixo36kppfedg.cloudfront.net/web/images/membership/priority-support.png",
        text: "Priority Customer Support",
        details: "Priority customer support only for members",
      },
      {
        image: "https://d1ixo36kppfedg.cloudfront.net/web/images/membership/exclusive.png",
        text: "Exclusive Deals",
        details: "Exclusive deals on top products only for members",
      },
    ],
  },
  {
    id: 2,
    type: "yearly",
    amount: "₹999",
    duration: "12 months",
    benefits: [
      {
        icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/membership/discount.png",
        headerText: "FLAT ₹30 Off",
        subHeaderText: "FLAT ₹30 discount on every product",
      },
      {
        icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/membership/exclusive.png",
        headerText: "15% Wallet Payment",
        subHeaderText: "Members can pay 15% from wallet",
        moreDetails: "Non members can pay 5% only",
      },
      {
        icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/membership/cashback.png",
        headerText:
          membershipDiscountData.timerEndDate.length > 0
            ? membershipDiscountData.offerPrice2.length > 0
              ? `₹${membershipDiscountData.offerPrice2} Cashback`
              : "₹999 Cashback"
            : "₹999 Cashback",
        subHeaderText:
          membershipDiscountData.timerEndDate.length > 0
            ? membershipDiscountData.offerPrice2.length > 0
              ? `Instant ₹${membershipDiscountData.offerPrice2} cashback on signup`
              : "Instant ₹999 cashback on signup"
            : "Instant ₹999 cashback on signup",
      },

      {
        image: "https://d1ixo36kppfedg.cloudfront.net/web/images/membership/priority-support.png",
        headerText: "Priority Customer Support",
        subHeaderText: "Priority customer support only for members ",
      },
      {
        icon: "https://d1ixo36kppfedg.cloudfront.net/web/images/membership/exclusive.png",
        headerText: "Exclusive Deals",
        subHeaderText: "Exclusive deals on top products only for members",
      },
    ],
  },
];

export const membershipProductsData = [
  {
    name: "",
    id: "",
    image: "",
    price: "",
    clubPrice: "",
    sizes: "",
    isCodAvailable: true,
    rating: 3.5,
  },
];

export const DEEP_LINK_MEMBERSHIP_999 =
  "https://glowroad.com/membership/payment?benefitId=3&planId=1";

export const DEEP_LINK_MEMBERSHIP_299 =
  "https://glowroad.com/membership/payment?benefitId=7&planId=5";

export const salesBannerCarouselData = [
  {
    id: 1,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/sales-page/new-images/cur1.png",
  },
  {
    id: 2,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/sales-page/new-images/cur2.png",
  },
  {
    id: 3,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/sales-page/new-images/cur3.png",
  },
];

export const salesGalleryOneData = [
  {
    id: 1,
    src: "sales-page/new-images/saree.png",
    href: "https://glowroad.app.link/IVeFf56Ei0",
  },
  {
    id: 2,
    src: "sales-page/new-images/kurta.png",
    href: "https://glowroad.app.link/0HBI6c6Fi0",
  },
  {
    id: 3,
    src: "sales-page/new-images/unstiched-DM.png",
    href: "https://glowroad.app.link/AoEuVD9Gi0",
  },
  {
    id: 4,
    src: "sales-page/new-images/mens.png",
    href: "https://glowroad.app.link/48NrjSyHi0",
  },
];

export const salesGalleryTwoData = [
  {
    id: 1,
    src: "sales-page/new-images/beauty.png",
    href: "https://glowroad.app.link/StjXrssIi0",
  },
  {
    id: 2,
    src: "sales-page/new-images/mobile-elect.png",
    href: "https://glowroad.app.link/u8amRXuIi0",
  },
  {
    id: 3,
    src: "sales-page/new-images/bedsheets.png",
    href: "https://glowroad.app.link/1ivLwSHHi0",
  },
  {
    id: 4,
    src: "sales-page/new-images/home&kithc.png",
    href: "https://glowroad.app.link/Pe4UnVaRi0",
  },
];

export const salesGalleryThreeData = [
  {
    id: 7,
    src: "sales-page/new-images/footwear.png",
    href: "https://glowroad.app.link/2DS685NHi0",
  },
  {
    id: 8,
    src: "sales-page/new-images/accessories.png",
    href: "https://glowroad.app.link/v4pE2ESHi0",
  },
  {
    id: 1,
    src: "sales-page/new-images/nyc.png",
    href: "https://glowroad.app.link/nK1XxjWHi0",
  },
  {
    id: 2,
    src: "sales-page/new-images/western-wear.png",
    href: "https://glowroad.app.link/dCQT72YHi0",
  },
];

export const salesGalleryFourData = [
  {
    id: 1,
    src: "sales-page/new-images/99.png",
    href: "https://glowroad.app.link/rmFP0kQIi0",
  },
  {
    id: 2,
    src: "sales-page/new-images/199.png",
    href: "https://glowroad.app.link/vXVK8CSIi0",
  },
  {
    id: 3,
    src: "sales-page/new-images/299.png",
    href: "https://glowroad.app.link/Cus7zUUIi0",
  },
  {
    id: 4,
    src: "sales-page/new-images/399.png",
    href: "https://glowroad.app.link/6dGCQGXIi0",
  },
  {
    id: 5,
    src: "sales-page/new-images/flat30.png",
    href: "https://glowroad.app.link/SXKrnlNIi0",
  },
  {
    id: 6,
    src: "sales-page/new-images/flat40.png",
    href: "https://glowroad.app.link/uqP3JSKIi0",
  },
  {
    id: 7,
    src: "sales-page/new-images/flat50.png",
    href: "https://glowroad.app.link/RC3sBEHIi0",
  },
  {
    id: 8,
    src: "sales-page/new-images/flat60.png",
    href: "https://glowroad.app.link/xuJ1vqDIi0",
  },
];

export const salesGalleryFiveData = [
  {
    id: 1,
    src: "sales-page/new-images/flash-1.png",
    href: "https://glowroad.app.link/UdDVuNjKi0",
  },
  {
    id: 2,
    src: "sales-page/new-images/flash-2.png",
    href: "https://glowroad.app.link/bvvhFLlKi0",
  },
];

export const salesEightGallery = [
  {
    id: 3,
    src: "sales-page/new-images/otherthnic.png",
    href: "https://glowroad.app.link/YY7qaKkIi0",
  },
  {
    id: 4,
    src: "sales-page/new-images/jew.png",
    href: "https://glowroad.app.link/vBR8HxnIi0",
  },
  {
    id: 5,
    src: "sales-page/new-images/baby-kids.png",
    href: "https://glowroad.app.link/fwJW3UpIi0",
  },
  {
    id: 6,
    src: "sales-page/new-images/home.png",
    href: "https://glowroad.app.link/8yKck7RRi0",
  },
];

export const salesGalleryNineData = [
  {
    id: 3,
    src: "sales-page/new-images/navrathri.png",
    href: "https://glowroad.app.link/cMUMShaJi0",
  },
  {
    id: 4,
    src: "sales-page/new-images/dhanrata-item.png",
    href: "https://glowroad.app.link/hWK3NLIPi0",
  },
  {
    id: 5,
    src: "sales-page/new-images/karva.png",
    href: "https://glowroad.app.link/DhEceAEJi0",
  },
  {
    id: 6,
    src: "sales-page/new-images/diwali-item.png",
    href: "https://glowroad.app.link/p1whj7wJi0",
  },
];

// Reseller Shopping Fest
export const dealImagesData = [
  {
    id: 1,
    href: "https://glowroad.app.link/5k6bRqA1D0",
    src: "reseller-shopping-fest/lightning-deals.png",
  },
  {
    id: 2,
    href: "https://glowroad.app.link/mgUEw8v1D0",
    src: "reseller-shopping-fest/rush-hour.png",
  },
];

export const offerOneImagesData = [
  {
    id: 1,
    href: "https://glowroad.app.link/WmXC5HPxD0",
    src: "reseller-shopping-fest/saree.png",
  },
  {
    id: 2,
    href: "https://glowroad.app.link/MHMSoj1xD0",
    src: "reseller-shopping-fest/kurtas.png",
  },
  {
    id: 3,
    href: "https://glowroad.app.link/bmZNcjTyD0",
    src: "reseller-shopping-fest/udm.png",
  },
  {
    id: 4,
    href: "https://glowroad.app.link/OTUdxl7yD0",
    src: "reseller-shopping-fest/mens-apparel.png",
  },
];

export const offerTwoImagesData = [
  {
    id: 1,
    href: "https://glowroad.app.link/9WQ8CqczD0",
    src: "reseller-shopping-fest/home-living.png",
  },
  {
    id: 2,
    href: "https://glowroad.app.link/Hrf2Be1CD0",
    src: "reseller-shopping-fest/kitchen.png",
  },
  {
    id: 3,
    href: "https://glowroad.app.link/2NKwFO3CD0",
    src: "reseller-shopping-fest/home-furnishing.png",
  },
  {
    id: 4,
    href: "https://glowroad.app.link/7LFiXCkCD0",
    src: "reseller-shopping-fest/footwear.png",
  },
];

export const offerThreeImagesData = [
  {
    id: 1,
    href: "https://glowroad.app.link/hEb3D2wCD0",
    src: "reseller-shopping-fest/accessories.png",
  },
  {
    id: 2,
    href: "https://glowroad.app.link/hfgnltYCD0",
    src: "reseller-shopping-fest/mobile-electronics.png",
  },
  {
    id: 3,
    href: "https://glowroad.app.link/Uec3nSHCD0",
    src: "reseller-shopping-fest/western-wear.png",
  },
  {
    id: 4,
    href: "https://glowroad.app.link/KgtNGOKCD0",
    src: "reseller-shopping-fest/other-ethnic.png",
  },
];

export const offerFourImagesData = [
  {
    id: 1,
    href: "https://glowroad.app.link/BwtPmIOCD0",
    src: "reseller-shopping-fest/jewellery.png",
  },
  {
    id: 2,
    href: "https://glowroad.app.link/70lJqHRCD0",
    src: "reseller-shopping-fest/baby-kids.png",
  },
  {
    id: 3,
    href: "https://glowroad.app.link/cjekrTUCD0",
    src: "reseller-shopping-fest/beauty-personal-care.png",
  },
  {
    id: 4,
    href: "https://glowroad.app.link/XZ2KFz1CD0",
    src: "reseller-shopping-fest/lingerie.png",
  },
];

export const discountsData = [
  {
    id: 1,
    href: "https://glowroad.app.link/N5greGlCD0",
    src: "reseller-shopping-fest/f30.png",
  },
  {
    id: 2,
    href: "https://glowroad.app.link/CVOJpksCD0",
    src: "reseller-shopping-fest/f40.png",
  },
  {
    id: 3,
    href: "https://glowroad.app.link/7ce9NCyCD0",
    src: "reseller-shopping-fest/f50.png",
  },
  {
    id: 4,
    href: "https://glowroad.app.link/x60VtLICD0",
    src: "reseller-shopping-fest/f60.png",
  },
];

export const discountOffersData = [
  {
    id: 1,
    href: "https://glowroad.app.link/7qOzOYoJD0",
    src: "reseller-shopping-fest/u99.png",
  },
  {
    id: 2,
    href: "https://glowroad.app.link/I6nPuxsJD0",
    src: "reseller-shopping-fest/u199.png",
  },
  {
    id: 3,
    href: "https://glowroad.app.link/cymcVLxJD0",
    src: "reseller-shopping-fest/u299.png",
  },
  {
    id: 4,
    href: "https://glowroad.app.link/BMiQKDBJD0",
    src: "reseller-shopping-fest/u399.png",
  },
];

export const awardsData = [
  {
    id: 1,
    href: "https://glowroad.com/earnmore/paytmcashback",
    src: "reseller-shopping-fest/paytm.png",
  },
  {
    id: 2,
    href: "#",
    src: "reseller-shopping-fest/top10.png",
  },
];

// wedding sale
export const resellByOccasionsData = [
  {
    id: 1,
    href: `${() => getEncodedMetaLink(1040)}`,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/wedding-sale/haldi.png",
  },
  {
    id: 2,
    href: `${() => getEncodedMetaLink(1042)}`,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/wedding-sale/mehandi.png",
  },
  {
    id: 3,
    href: `${() => getEncodedMetaLink(1041)}`,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/wedding-sale/sangeet.png",
  },
  {
    id: 4,
    href: `${() => getEncodedMetaLink(1043)}`,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/wedding-sale/shubh-vivaah.png",
  },
];

export const resellByCategoryData = [
  {
    id: 1,
    href: `${() => getEncodedMetaLink(1033)}`,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/wedding-sale/lehanga.png",
  },
  {
    id: 2,
    href: `${() => getEncodedMetaLink(1051)}`,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/wedding-sale/saree.png",
  },
  {
    id: 3,
    href: `${() => getEncodedMetaLink(1054)}`,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/wedding-sale/dress.png",
  },
  {
    id: 4,
    href: `${() => getEncodedMetaLink(1053)}`,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/wedding-sale/kurti.png",
  },
  {
    id: 5,
    href: `${() => getEncodedMetaLink(1052)}`,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/wedding-sale/ethnic.png",
  },
  {
    id: 6,
    href: `${() => getEncodedMetaLink(672)}`,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/beauty.png",
  },
];

export const resellByWeddingStyleData = [
  {
    id: 1,
    href: `${() => getEncodedMetaLink(1035)}`,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/punjabi.png",
  },
  {
    id: 2,
    href: `${() => getEncodedMetaLink(1036)}`,
    src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/south-indian.png",
  },
];

export const diwaliBonusSalesData = {
  offersOne: [
    {
      id: 1,
      href: `${() => getEncodedMetaLink(836)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/flash_sale_1.png",
    },
    {
      id: 2,
      href: `${() => getEncodedMetaLink(902)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/flash_sale_2.png",
    },
  ],
  offersTwo: [
    {
      id: 1,
      href: `${() => getEncodedMetaLink(914)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/diwali_item.png",
    },
    {
      id: 2,
      href: `${() => getEncodedMetaLink(902)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/cash_back.png",
    },
  ],
  offersThree: [
    {
      id: 1,
      href: `${() => getEncodedMetaLink(902)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/rated.png",
    },
    {
      id: 2,
      href: `${() => getEncodedMetaLink(84)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/most.png",
    },
  ],
  offersFour: [
    {
      id: 1,
      href: `${() => getEncodedMetaLink(912)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/east_trends.png",
    },
    {
      id: 2,
      href: `${() => getEncodedMetaLink(913)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/west_tales.png",
    },
  ],
  offersFive: [
    {
      id: 1,
      href: `${() => getEncodedMetaLink(1046)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/ethnic_under_599.png",
    },
    {
      id: 2,
      href: `${() => getEncodedMetaLink(1047)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/ethnic_under_799.png",
    },
  ],
  offersSix: [
    {
      id: 1,
      href: `${() => getEncodedMetaLink(1071)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/seasons_trends.png",
    },
    {
      id: 2,
      href: `${() => getEncodedMetaLink(673)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/only_on_glowroad.png",
    },
  ],
  offersSeven: [
    {
      id: 1,
      href: `${() => getEncodedMetaLink(902)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/rated.png",
    },
    {
      id: 2,
      href: `${() => getEncodedMetaLink(902)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/only_on_glowroad.png",
    },
  ],
  discountOne: [
    {
      id: 1,
      href: `${() => getEncodedMetaLink(939)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/99.png",
    },
    {
      id: 2,
      href: `${() => getEncodedMetaLink(940)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/199.png",
    },
    {
      id: 3,
      href: `${() => getEncodedMetaLink(942)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/299.png",
    },
    {
      id: 4,
      href: `${() => getEncodedMetaLink(943)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/399.png",
    },
  ],
  discountTwo: [
    {
      id: 1,
      href: `${() => getEncodedMetaLink(895)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/30_off.png",
    },
    {
      id: 2,
      href: `${() => getEncodedMetaLink(896)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/60_off.png",
    },
    {
      id: 3,
      href: `${() => getEncodedMetaLink(898)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/80_off.png",
    },
    {
      id: 4,
      href: `${() => getEncodedMetaLink(919)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/100_off.png",
    },
  ],
  categoriesOne: [
    {
      id: 1,
      href: `${() => getEncodedMetaLink(1048)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_saree.png",
    },
    {
      id: 2,
      href: `${() => getEncodedMetaLink(1038)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_kurtas.png",
    },
    {
      id: 3,
      href: `${() => getEncodedMetaLink(1062)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_dress_material.png",
    },
    {
      id: 4,
      href: `${() => getEncodedMetaLink(932)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_mens.png",
    },
  ],
  categoriesTwo: [
    {
      id: 1,
      href: `${() => getEncodedMetaLink(1061)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_home_living.png",
    },
    {
      id: 2,
      href: `${() => getEncodedMetaLink(884)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_kitchen.png",
    },
    {
      id: 3,
      href: `${() => getEncodedMetaLink(54)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_home_furnishing.png",
    },
    {
      id: 4,
      href: `${() => getEncodedMetaLink(936)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_footwear.png",
    },
  ],
  categoriesThree: [
    {
      id: 1,
      href: `${() => getEncodedMetaLink(944)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_accessories.png",
    },
    {
      id: 2,
      href: `${() => getEncodedMetaLink(1084)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_western_wear.png",
    },
    {
      id: 3,
      href: `${() => getEncodedMetaLink(1069)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_ethnic.png",
    },
    {
      id: 4,
      href: `${() => getEncodedMetaLink(930)}`,
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_jewellery.png",
    },
  ],
  categoriesFour: [
    {
      id: 1,
      href: () => getEncodedMetaLink(1083),
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_baby.png",
    },
    {
      id: 2,
      href: () => getEncodedMetaLink(78),
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_beauty.png",
    },
    {
      id: 3,
      href: () => getEncodedMetaLink(1073),
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_nightwear.png",
    },
    {
      id: 4,
      href: () => getEncodedMetaLink(424),
      src: "https://d1ixo36kppfedg.cloudfront.net/web/images/diwali-bonus-sale/img_mobile.png",
    },
  ],
};

export const metaLinksData = [
  {
    path: "META_COLLECTION_LIST",
    objectId: "935",
    objectType: "Sale - All Categories",
  },
  { path: "META_COLLECTION_LIST", objectId: "1048", objectType: "Sarees" },
  { path: "META_COLLECTION_LIST", objectId: "1038", objectType: "Kurtas" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1062",
    objectType: "Unstitched Dress Material",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "932",
    objectType: "Men's Apparel",
  },
  { path: "META_COLLECTION_LIST", objectId: "945", objectType: "Bedsheets" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "944",
    objectType: "Watches & Wearables, Bags & Wallets",
  },
  { path: "META_COLLECTION_LIST", objectId: "947", objectType: "NYC" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1084",
    objectType: "Western Wear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1069",
    objectType: "Other Ethnic Wear",
  },
  { path: "META_COLLECTION_LIST", objectId: "930", objectType: "Jewellery" },
  { path: "META_COLLECTION_LIST", objectId: "1083", objectType: "Baby & Kids" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "78",
    objectType: "Beauty & Personal",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "424",
    objectType: "Mobiles & Electronics",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1073",
    objectType: "Lingerie & Nightwear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "530",
    objectType: "International",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1343",
    objectType: "Min Rs 100 Off",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1342",
    objectType: "Min Rs 80 Off",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1341",
    objectType: "Min Rs 60 Off",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1340",
    objectType: "Min Rs 30 Off",
  },
  { path: "META_COLLECTION_LIST", objectId: "939", objectType: "Under 99" },
  { path: "META_COLLECTION_LIST", objectId: "1357", objectType: "Under 199" },
  { path: "META_COLLECTION_LIST", objectId: "1358", objectType: "Under 299" },
  { path: "META_COLLECTION_LIST", objectId: "943", objectType: "Under 399" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "908",
    objectType: "TV and Bollywood Style",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "910",
    objectType: "Trending in North",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "911",
    objectType: "Trending in South",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "913",
    objectType: "Trending in West",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "912",
    objectType: "Trending in East",
  },
  { path: "META_COLLECTION_LIST", objectId: "914", objectType: "Diwali Items" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "836",
    objectType: "FLASH SALE - Live @ 20TH NOV 11AM",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "948",
    objectType: "FLASH SALE - Live @ 13TH DEC 11AM",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "949",
    objectType: "FLASH SALE - LIVE @ 13TH DEC 8.30 PM",
  },
  { path: "META_COLLECTION_LIST", objectId: "950", objectType: "FLASH SALE" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "953",
    objectType: "FLASH SALE - LIVE @ 22ND NOV 8 PM",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "954",
    objectType: "FLASH SALE - Live @ 23RD NOV 11AM",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "957",
    objectType: "FLASH SALE - LIVE @ 24TH NOV 8:30 PM",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1128",
    objectType: "FLASH SALE - LIVE @ 25TH NOV 8 PM",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "955",
    objectType: "FLASH SALE - Live @ 26TH NOV 11AM",
  },
  { path: "META_COLLECTION_LIST", objectId: "951", objectType: "FLASH SALE" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "952",
    objectType: "FLASH SALE - Live @ 15TH DEC 11AM",
  },
  { path: "META_COLLECTION_LIST", objectId: "1034", objectType: "Buy 1 Get 1" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "884",
    objectType: "Home & Kitchen",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "54",
    objectType: "Home Furnishing",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "84",
    objectType: "Most Profitable Catalogs",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1071",
    objectType: "Season's Trends",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1072",
    objectType: "Hourly Deals 9AM - 9PM",
  },
  { path: "META_COLLECTION_LIST", objectId: "1061", objectType: "Bedsheets" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1046",
    objectType: "Ethnic Under 599",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1047",
    objectType: "Ethnic Under 799",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1039",
    objectType: "Bridal Collection",
  },
  { path: "META_COLLECTION_LIST", objectId: "1040", objectType: "Haldi" },
  { path: "META_COLLECTION_LIST", objectId: "1041", objectType: "Sangeet" },
  { path: "META_COLLECTION_LIST", objectId: "1042", objectType: "Mehendi" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1043",
    objectType: "Shubh Vivaah",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1033",
    objectType: "Lehenga & Other Ethnic",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1053",
    objectType: "Kurti & Sets",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1051",
    objectType: "Saree & Jewellery",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1052",
    objectType: "Kids Ethnic Wear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1054",
    objectType: "Dress Material",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "672",
    objectType: "Beauty & Make up",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1035",
    objectType: "Punjabi/North Indian Wedding",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1036",
    objectType: "South Indian Wedding",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1037",
    objectType: "Destination Wedding",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1044",
    objectType: "Pehla Karwa Chauth",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1128",
    objectType: "FLASH SALE - Live @ 24TH OCT 10 PM",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1142",
    objectType: "Its Colour dress day at school",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1147",
    objectType: "Imagine & transform-Fancy dress costumes",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1150",
    objectType: "Warm Winter Wishes",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1153",
    objectType: "Balle Balle Shadi Wali Party Looks",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1145",
    objectType: "Angel or devil? Happy halloween",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1209",
    objectType: "Kids Clearance Sale 10th Nov",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1210",
    objectType: "kids Happy Hours Sale 13th nov",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1211",
    objectType: "Its colour dress day at school",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1212",
    objectType: "Fancy Dress costume 3",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1213",
    objectType: "Just Chill ! Winter Wardrobe collections",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1214",
    objectType: "Cartoon Network 2",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1215",
    objectType: "Make Your Lill One Look Like a Princess",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1216",
    objectType: "Animal Theme Dresses",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1217",
    objectType: "Mad For Bollywood",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1218",
    objectType: "Kids Future men",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1219",
    objectType: "Kids Sardiyon Wali Shadi Ke liye",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1220",
    objectType: "Kids Flat 60",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1221",
    objectType: "Kids Flat 50",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1222",
    objectType: "Kids Flat 40",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1223",
    objectType: "Kids Flat 30",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1224",
    objectType: "Kids Under 199",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1225",
    objectType: "kids under 299",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1226",
    objectType: "kids under 399",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1227",
    objectType: "kids under 599",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1228",
    objectType: "kids under 799",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1229",
    objectType: "Reseller's Fav Collections Of frocks",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1230",
    objectType: "Kids Popular Brands Under One Roof",
  },
  { path: "META_COLLECTION_LIST", objectId: "1231", objectType: "Infants 2" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1232",
    objectType: "kids 2-6 Years",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1233",
    objectType: "Kids 6-12 years",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1234",
    objectType: "Kids 12- 16 Years",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1235",
    objectType: "Kids Playtime Zone",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1236",
    objectType: "KIds Sleep Time Zone",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1237",
    objectType: "Kids Hobbies Zone",
  },
  { path: "META_COLLECTION_LIST", objectId: "1238", objectType: "Boys Ethnic" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1239",
    objectType: "Girl's Ethnic",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1240",
    objectType: "Boys T shirts",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1241",
    objectType: "Kids Clothing Set",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1242",
    objectType: "Kids Footwear 2",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1243",
    objectType: "Kids Watches 2",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1244",
    objectType: "Kids Romper & Dungaree",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1245",
    objectType: "Kids Combo Collections",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1246",
    objectType: "Infant Winter Wear & Booties",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1247",
    objectType: "Kids Lehengas",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1248",
    objectType: "Kids Tops & Tees",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1249",
    objectType: "Kids Imported",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1250",
    objectType: "Baby Bedding & Quilts",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1251",
    objectType: "Kids Innerwear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1252",
    objectType: "Kids Accessories",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1290",
    objectType: "New Arrival Imported Jewellery",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1289",
    objectType: "Imported Jewellery All",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1284",
    objectType: "Top 100 @ Huge Discounts",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1283",
    objectType: "Others Jewelry @ Huge Discounts",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1282",
    objectType: "Imported Necklaces @ Huge Discounts",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1281",
    objectType: "Imported Jewellery Sets @ Huge Discounts",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1276",
    objectType: "Imported Earrings @ Huge Discounts",
  },
  { path: "META_COLLECTION_LIST", objectId: "1306", objectType: "Men" },
  { path: "META_COLLECTION_LIST", objectId: "1307", objectType: "Women" },
  { path: "META_COLLECTION_LIST", objectId: "1308", objectType: "Kids" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "Shop by Products",
    objectType: "",
  },
  { path: "META_COLLECTION_LIST", objectId: "1309", objectType: "Sweatshirts" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1310",
    objectType: "Sweaters & Cardigans",
  },
  { path: "META_COLLECTION_LIST", objectId: "1311", objectType: "Shrugs" },
  { path: "META_COLLECTION_LIST", objectId: "1312", objectType: "Jackets" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1313",
    objectType: "Coats & Blazers",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1314",
    objectType: "Thermals & Woolen Leggings",
  },
  { path: "META_COLLECTION_LIST", objectId: "1301", objectType: "Shawls" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1318",
    objectType: "Personal Care",
  },
  { path: "META_COLLECTION_LIST", objectId: "Shop by Trends", objectType: "" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1319",
    objectType: "Yellow Story",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1320",
    objectType: "Long Dresses",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1321",
    objectType: "Velvet & Woolen",
  },
  { path: "META_COLLECTION_LIST", objectId: "1322", objectType: "Checks" },
  { path: "META_COLLECTION_LIST", objectId: "1323", objectType: "Active Wear" },
  { path: "META_COLLECTION_LIST", objectId: "1324", objectType: "Denim" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1325",
    objectType: "Long Sleeves",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1326",
    objectType: "Pashmina Silk",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1330",
    objectType: "Kids Combo Collections",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "156",
    objectType: "Weaves of Bengal",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "157",
    objectType: "Banarasi silk sarees",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1332",
    objectType: "Rajasthan Special Sarees",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1157",
    objectType: "Kalamkari Sarees",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1333",
    objectType: "Tamil Nadu Special Sarees",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "738",
    objectType: "Kerala Sarees",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "473",
    objectType: "Handloom Saree Santhe",
  },
  { path: "META_COLLECTION_LIST", objectId: "1346", objectType: "Bedding Set" },
  { path: "META_COLLECTION_LIST", objectId: "1354", objectType: "Footwear" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1348",
    objectType: "Western Wear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1339",
    objectType: "Other Ethnic Wear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1175",
    objectType: "Lingerie & Nightwear",
  },
  { path: "META_COLLECTION_LIST", objectId: "1349", objectType: "Sarees" },
  { path: "META_COLLECTION_LIST", objectId: "1350", objectType: "Jewellery" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1364",
    objectType: "Deals Ka Baap",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "921",
    objectType: "Deals Ka Baap Sale From 49 @ 13TH Dec 8PM",
  },
  { path: "META_COLLECTION_LIST", objectId: "1366", objectType: "Dulhaniyaa" },
  { path: "META_COLLECTION_LIST", objectId: "1367", objectType: "Dilwale" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1368",
    objectType: "Gifts for Her",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1369",
    objectType: "Gifts for Him",
  },
  { path: "META_COLLECTION_LIST", objectId: "1370", objectType: "Sagaai" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1371",
    objectType: "Haldi & Mehandi",
  },
  { path: "META_COLLECTION_LIST", objectId: "1372", objectType: "Cocktail" },
  { path: "META_COLLECTION_LIST", objectId: "1373", objectType: "Sangeet" },
  { path: "META_COLLECTION_LIST", objectId: "1374", objectType: "Reception" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "958",
    objectType: "FLASH SALE - Live @ 28TH NOV 11AM",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "921",
    objectType: "Deals Ka Baap Sale From 49 @ 28TH Nov 8PM",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "956",
    objectType: "FLASH SALE - LIVE @ 28TH NOV 8:30 PM",
  },
  { path: "META_COLLECTION_LIST", objectId: "1405", objectType: "Be like Dad" },
  { path: "META_COLLECTION_LIST", objectId: "1406", objectType: "Be Like Mom" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1270",
    objectType: "Sleepwear & More",
  },
  { path: "META_COLLECTION_LIST", objectId: "1409", objectType: "Khaadi" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1444",
    objectType: "North & East Zone- Baby&Kids",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1445",
    objectType: "South & West Zone- Baby&Kids",
  },
  { path: "META_COLLECTION_LIST", objectId: "1437", objectType: "Sarees" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1442",
    objectType: "Unstitched Dress Material",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1453",
    objectType: "Lehenge - Other Ethnic Wear",
  },
  { path: "META_COLLECTION_LIST", objectId: "1447", objectType: "Jewellery" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1433",
    objectType: "Lingerie & Nightwear",
  },
  { path: "META_COLLECTION_LIST", objectId: "1412", objectType: "Under 99" },
  { path: "META_COLLECTION_LIST", objectId: "1413", objectType: "Under 199" },
  { path: "META_COLLECTION_LIST", objectId: "1414", objectType: "Under 399" },
  { path: "META_COLLECTION_LIST", objectId: "942", objectType: "Under 299" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1448",
    objectType: "Republic Day Merchandise",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1450",
    objectType: "Gadgets Guru",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1391",
    objectType: "Christmas - House of Santa",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1434",
    objectType: "Most Profitable Products",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1467",
    objectType: "Kids-Kurta Set",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1468",
    objectType: "Kids-Kurta Set With Waist Coat",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1469",
    objectType: "Kids- Nehru Jacket",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1470",
    objectType: "Kids-Sherwani",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1452",
    objectType: "Lehenga & Other Ethnic",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1451",
    objectType: "Nightwear & lingery",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1457",
    objectType: "Sarees for Traditional Brides",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1458",
    objectType: "Sarees for Minimalistic Brides",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1459",
    objectType: "Sarees for Bindass Brides",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1460",
    objectType: "Sarees for Bridesmaid",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1461",
    objectType: "Saree Wedding Gifts",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1462",
    objectType: "Wedding Saree Lookbook",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1496",
    objectType: "Bridal Accessories",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1497",
    objectType: "Bridal Sarees",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "839",
    objectType: "Bridal Jewellery",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1425",
    objectType: "Pear Body Shape",
  },
  { path: "META_COLLECTION_LIST", objectId: "1427", objectType: "Hourglass" },
  { path: "META_COLLECTION_LIST", objectId: "1430", objectType: "Oval" },
  { path: "META_COLLECTION_LIST", objectId: "1428", objectType: "Apple" },
  { path: "META_COLLECTION_LIST", objectId: "1506", objectType: "Kerala" },
  { path: "META_COLLECTION_LIST", objectId: "1507", objectType: "Punjab" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1508",
    objectType: "Uttar Pradesh",
  },
  { path: "META_COLLECTION_LIST", objectId: "1509", objectType: "Rajasthan" },
  { path: "META_COLLECTION_LIST", objectId: "1510", objectType: "Gujarat" },
  { path: "META_COLLECTION_LIST", objectId: "1511", objectType: "Alia Bhatt" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1512",
    objectType: "Sonam Kapoor",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1513",
    objectType: "Sharaddha Kapoor",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1514",
    objectType: "Aishwarya Rai",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1515",
    objectType: "Sara Ali Khan",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "189",
    objectType: "NYC Exclusive on GlowRoad",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1554",
    objectType: "LOHRI- Kids/UDM/Lehenga/LN",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1579",
    objectType: "Pongal- Kids UDM LN Lehenga",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1556",
    objectType: "Bihu Collection",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1557",
    objectType: "MAKAR SANKRANTI",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1588",
    objectType: "Kids-Republic Day Best Deals",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1589",
    objectType: "Kids-Dance Competition Costumes",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1590",
    objectType: "Kids-Fancy Dress Costumes",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1591",
    objectType: "Kids-Speech Competition Costumes",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1592",
    objectType: "Kids-Singing Competition Costumes",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1593",
    objectType: "Kids-Painting Competition Collections",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1594",
    objectType: "Kids-tri colour Decorations & Accessories",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1595",
    objectType: "Kids-Tri Colour Costumes",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1604",
    objectType: "pongal dress material",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1602",
    objectType: "lohri dress material",
  },
  { path: "META_COLLECTION_LIST", objectId: "1598", objectType: "udm bihu" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1597",
    objectType: "Makar Sankranti UDM",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1601",
    objectType: "PONGAL- KIDS",
  },
  { path: "META_COLLECTION_LIST", objectId: "1599", objectType: "LOHRI- kids" },
  { path: "META_COLLECTION_LIST", objectId: "1606", objectType: "BIHU- KIDS" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1600",
    objectType: "MAKAR SANKRANTI- KIDS",
  },
  { path: "META_COLLECTION_LIST", objectId: "1603", objectType: "LN - LOHRI" },
  { path: "META_COLLECTION_LIST", objectId: "1605", objectType: "LN - Bihu" },
  { path: "META_COLLECTION_LIST", objectId: "1607", objectType: "LN - PONGAL" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1608",
    objectType: "LN - MAKAR SANKRANTI",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1617",
    objectType: "Pongal Lehengas ",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1618",
    objectType: "Pongal Ethnic Gowns",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1619",
    objectType: "Pongal Ethnic Bottom",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1620",
    objectType: "Makar Sankranti Lehengas",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1621",
    objectType: "Makar Sankranti Ethnic Gowns",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1622",
    objectType: "Makar Sankranti Ethnic Bottom",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1623",
    objectType: "Lohri Ethnic Lehengas",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1624",
    objectType: "Lohri Ethnic Gowns",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1625",
    objectType: "Lohri Ethnic Bottoms",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1626",
    objectType: "Bihu Ethnic Lehengas",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1627",
    objectType: "Bihu Ethnic Gowns",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1628",
    objectType: "Bihu Ethnic Bottoms",
  },
  { path: "META_COLLECTION_LIST", objectId: "1648", objectType: "Sarees" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1642",
    objectType: "Unstitched Dress Material",
  },
  { path: "META_COLLECTION_LIST", objectId: "1672", objectType: "Bedsheets" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1650",
    objectType: "Lehenge - Other Ethnic Wear",
  },
  { path: "META_COLLECTION_LIST", objectId: "1577", objectType: "Jewellery" },
  { path: "META_COLLECTION_LIST", objectId: "1449", objectType: "Kurtas" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "558",
    objectType: "Dry Fruits Sale",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1653",
    objectType: "NYC - International Cosmetics",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1671",
    objectType: "Home Furnishing",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1477",
    objectType: "Makar Sankranti Sarees",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1675",
    objectType: "Makar Sankranti Kurtas",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1597",
    objectType: "Makar Sankranti Dress Material",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1620",
    objectType: "Makar Sankranti Lehengas",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1622",
    objectType: "Makar Sankranti Ethnic Bottoms",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1658",
    objectType: "Makar Sankranti Blouses",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1621",
    objectType: "Makar Sankranti Ethnic Gowns",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1659",
    objectType: "Makar Sankranti Jewellery",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1667",
    objectType: "Makar Sankranti Dresses",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1600",
    objectType: "Makar Sankranti Baby & Kids",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1608",
    objectType: "Makar Sankranti Lingerie",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1665",
    objectType: "Makar Sankranti Makeup",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1571",
    objectType: "Lohri Sarees",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1674",
    objectType: "Lohri Kurtas",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1602",
    objectType: "Lohri Dress Material",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1623",
    objectType: "Lohri Lehengas",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1625",
    objectType: "Lohri Ethnic Bottoms",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1656",
    objectType: "Lohri Blouses",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1624",
    objectType: "Lohri Ethnic Gowns",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1657",
    objectType: "Lohri Jewellery",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1668",
    objectType: "Lohri Dresses",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1599",
    objectType: "Lohri Baby & Kids",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1603",
    objectType: "Lohri Lingerie",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1663",
    objectType: "Lohri Makeup",
  },

  { path: "META_COLLECTION_LIST", objectId: "1660", objectType: "Bihu Sarees" },
  { path: "META_COLLECTION_LIST", objectId: "1676", objectType: "Bihu Kurtas" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1598",
    objectType: "Bihu Dress Material",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1626",
    objectType: "Bihu Lehengas",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1628",
    objectType: "Bihu Ethnic Bottoms",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1661",
    objectType: "Bihu Blouses",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1627",
    objectType: "Bihu Ethnic Gowns",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1662",
    objectType: "Bihu Jewellery",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1670",
    objectType: "Bihu Dresses",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1606",
    objectType: "Bihu Baby & Kids",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1605",
    objectType: "Bihu Lingerie",
  },
  { path: "META_COLLECTION_LIST", objectId: "1663", objectType: "Bihu Makeup" },

  {
    path: "META_COLLECTION_LIST",
    objectId: "1572",
    objectType: "Pongal Sarees",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1677",
    objectType: "Pongal Kurtas",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1604",
    objectType: "Pongal Dress Material",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1617",
    objectType: "Pongal Lehengas",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1619",
    objectType: "Pongal Ethnic Bottoms",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1654",
    objectType: "Pongal Blouses",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1618",
    objectType: "Pongal Ethnic Gowns",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "95",
    objectType: "Pongal Jewellery",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1669",
    objectType: "Pongal Dresses",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1601",
    objectType: "Pongal Baby & Kids",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1607",
    objectType: "Pongal Lingerie",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1664",
    objectType: "Pongal Makeup",
  },

  // Premium Store
  { path: "META_COLLECTION_LIST", objectId: "1682", objectType: "Sarees" },
  { path: "META_COLLECTION_LIST", objectId: "1709", objectType: "Kurtas" },
  { path: "META_COLLECTION_LIST", objectId: "1710", objectType: "UDM" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1711",
    objectType: "Other Ethnic Wear",
  },
  { path: "META_COLLECTION_LIST", objectId: "1712", objectType: "Jewellery" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1713",
    objectType: "Bags & Wallets",
  },
  { path: "META_COLLECTION_LIST", objectId: "1714", objectType: "Baby & Kids" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1715",
    objectType: "Western wear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1716",
    objectType: "Beauty & Personal Care",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1717",
    objectType: "Lingerie & Nightwear",
  },
  { path: "META_COLLECTION_LIST", objectId: "1736", objectType: "Footwear" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1719",
    objectType: "Watches & Wearables",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1720",
    objectType: "Home & Living - Bedsheets",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1721",
    objectType: "Home & Living - Kitchen",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1722",
    objectType: "Home & Living - Furnishing",
  },
  { path: "META_COLLECTION_LIST", objectId: "1723", objectType: "Men Apparel" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1765",
    objectType: "Mobiles & Electronics",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1766",
    objectType: "Exclusive Beauty & Personal Care",
  },

  // Valentines Day Sale
  {
    path: "META_COLLECTION_LIST",
    objectId: "1690",
    objectType: "Valentine Special Sarees",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1692",
    objectType: "Valentine Special Blouses",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1694",
    objectType: "V-Day Date Looks",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1695",
    objectType: "Valentine Gift Guide (S&J)",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1691",
    objectType: "Valentine's Fashion Accessories",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1693",
    objectType: "Valentine's Pendant",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1696",
    objectType: "Valentine's Rings",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1697",
    objectType: "Valentine's Earrings",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1698",
    objectType: "Valentine's Bracelets",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1699",
    objectType: "Valentine's Just For Him Jewels",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1701",
    objectType: "Valentine's Just For Her J&A",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1793",
    objectType: "Valentine's Perfect Match Couple",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1702",
    objectType: "Valentine's Sweet Surprise S&J",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1703",
    objectType: "Valentine's Two For One Deals S&J",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1759",
    objectType: "Green Color - S&J",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1760",
    objectType: "Red Color- S&J",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1761",
    objectType: "White Color- S&J",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1762",
    objectType: "Orange Color - S&J",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1763",
    objectType: "Pink Color - S&J",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "2312",
    objectType: "Kurtis & Gowns",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3327",
    objectType: "Shirts & More",
  },
  { path: "META_COLLECTION_LIST", objectId: "936", objectType: "Footwear" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3546",
    objectType: "Dress Materials",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3394",
    objectType: "Dresses & More",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3527",
    objectType: "Jewellery & Accessories",
  },
  { path: "META_COLLECTION_LIST", objectId: "3811", objectType: "Bedsheets" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3297",
    objectType: "Kitchen Essentials",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3526",
    objectType: "Beauty & Personal Care",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "1083",
    objectType: "Kidswear & More",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3748",
    objectType: "Furnishing & More",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "2272",
    objectType: "Innerwear & Nightwear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3098",
    objectType: "Bags & Wallets",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3738",
    objectType: "Men's Accessories",
  },
  { path: "META_COLLECTION_LIST", objectId: "2147", objectType: "Buy 1 Get 1" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "673",
    objectType: "Only on GlowRoad",
  },
  { path: "META_COLLECTION_LIST", objectId: "696", objectType: "4+ Star" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3688",
    objectType: "NYC & L'Glam",
  },

  { path: "META_COLLECTION_LIST", objectId: "3571", objectType: "Sarees" },
  { path: "META_COLLECTION_LIST", objectId: "2312", objectType: "Kurtis" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3546",
    objectType: "Ladies Suits",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3737",
    objectType: "Jewellery & Other Ethnic",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3001",
    objectType: "Make Up & More",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "936",
    objectType: "Footwear (use women ethnic footwear picture)",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3433",
    objectType: "Kid's Ethnic Wear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "2675",
    objectType: "Watches",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3718",
    objectType: "Lehengas & Bottomwear",
  },
  { path: "META_COLLECTION_LIST", objectId: "3086", objectType: "Hair Care" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3192",
    objectType: "Men's Ethnic Wear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "2877",
    objectType: "Ethnic Gowns",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3743",
    objectType: "Ethnic Bottomwear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3759",
    objectType: "Women's Innerwear",
  },
  { path: "META_COLLECTION_LIST", objectId: "510", objectType: "Blouses" },
  { path: "META_COLLECTION_LIST", objectId: "3005", objectType: "Skin Care" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3525",
    objectType: "Electronics & More",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3742",
    objectType: "Men's Jewellery",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3813",
    objectType: "Tops, Dresses & Jeans",
  },
  { path: "META_COLLECTION_LIST", objectId: "3487", objectType: "Nightwear" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3819",
    objectType: "Kidswear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3821",
    objectType: "Footwear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3191",
    objectType: "Menswear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "2724",
    objectType: "Watches, Bags & Accessories",
  },
  { path: "META_COLLECTION_LIST", objectId: "3684", objectType: "Home" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "2681",
    objectType: "Grocery",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3836",
    objectType: "Winter Essentials",
  },
  { path: "META_COLLECTION_LIST", objectId: "3593", objectType: "Footwear" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "2975",
    objectType: "STYLEROAD Men & Women",
  },
  { path: "META_COLLECTION_LIST", objectId: "3688", objectType: "NYC/LGLAM" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3461",
    objectType: "Men's T- shirts",
  },
  { path: "META_COLLECTION_LIST", objectId: "3580", objectType: "Dresses" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3587",
    objectType: "Indo-Western",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3583",
    objectType: "Top & Bottom Sets",
  },
  { path: "META_COLLECTION_LIST", objectId: "3065", objectType: "Nightwear" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3066",
    objectType: "Women's Innerwear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3524",
    objectType: "Men's Bottom Wear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3437",
    objectType: "Girl's Frocks & More",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3585",
    objectType: "Women's Bottomwear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3443",
    objectType: "Boy's Western Wear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "2830",
    objectType: "Women's Watches",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3788",
    objectType: "Men's Accessories",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3345",
    objectType: "Men's Grooming",
  },
  { path: "META_COLLECTION_LIST", objectId: "3008", objectType: "Sarees" },
  { path: "META_COLLECTION_LIST", objectId: "2858", objectType: "Kurtis" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3455",
    objectType: "Dress Materials",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3429",
    objectType: "Kid's Ethnic Wear",
  },
  { path: "META_COLLECTION_LIST", objectId: "", objectType: "Make-Up" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3192",
    objectType: "Men's Ethnic Wear",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "2724",
    objectType: "Watches, Bags & Accessories",
  },
  { path: "META_COLLECTION_LIST", objectId: "3839", objectType: "Home" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "2681",
    objectType: "Kitchen & Food",
  },
  { path: "META_COLLECTION_LIST", objectId: "", objectType: "Electronics" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3836",
    objectType: "Winter Essentials",
  },
  { path: "META_COLLECTION_LIST", objectId: "3810", objectType: "Bedsheets" },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3016",
    objectType: "Headphone & More",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3188",
    objectType: "Smart Watches",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3809",
    objectType: "Cookware & Dining",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3492",
    objectType: "Power Banks",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3329",
    objectType: "Mobile Accessories",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "2801",
    objectType: "Cleaning & Household",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3450",
    objectType: "Kids & Office Stationery",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3745",
    objectType: "Bestsellers of Home",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3834",
    objectType: "Sports & Fitness",
  },
  {
    path: "META_COLLECTION_LIST",
    objectId: "3031",
    objectType: "Small Appliances",
  },
  { path: "META_COLLECTION_LIST", objectId: "3810", objectType: "Bedsheets" },
];

export const collectionLinksData = [
  { path: "collectionProduct", objectId: "107899" }, // patch
  { path: "collectionProduct", objectId: "107901" }, // rashes
  { path: "collectionProduct", objectId: "107903" }, // activities
  { path: "collectionProduct", objectId: "107905" },
  { path: "collectionProduct", objectId: "107914" },
  { path: "collectionProduct", objectId: "107908" }, // black pad
  { path: "collectionProduct", objectId: "107909" }, // disposable
  { path: "collectionProduct", objectId: "107915" }, // pampers
  { path: "collectionProduct", objectId: "107916" },
  { path: "collectionProduct", objectId: "107917" }, // hygiene
  { path: "collectionProduct", objectId: "107912" },
  { path: "collectionProduct", objectId: "107918" }, // travel
];

export const productDetailsData = [
  { path: "productDetail", objectId: "743363745" },
  { path: "productDetail", objectId: "437823039" },
  { path: "productDetail", objectId: "750366749" },
  { path: "productDetail", objectId: "750327451" },
];
