export default {
  colors: {
    textLight: "#ccc",
    primary: "#eb751e",
    borderColor: "#ededed",
    blue: "#0092ff",
    backgroundLight: "#f5f5f5",
    red: "#bb0000",
    darkPeach: "#ea6f6f",
    orange: "rgb(255,85,0)",
    darkBlue: "#0072a3",
    offWhite: "#f8f8f8",
    green: "#73c04d",
    darkGreen: "#54b226"
  },
  layout: {
    navbarHeight: "50px"
  },
  fonts: {
    osc: "'Open Sans Condensed', sans-serif",
    bc: "'Barlow Condensed',sans-serif",
    roboto: "Roboto",
    azo: "AzoSans-Black",
    bebas: "BebasNeue Bold",
    montserrat: "'Montserrat'",
    avenirRoman: "'Avenir Roman'"
  }
};
