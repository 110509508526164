import styled from 'styled-components';
import { Flex, MediaQ } from '../../../styled-components/utils';
import { Input } from '../../../styled-components/form-elements';
import { Button } from '../../../styled-components/buttons';

export const Wrapper = styled.div`
  ${Flex('column nowrap', 'center', 'flex-start')};
  height: 100%
  background: white;
  width: 100%;
  border-radius: 6px;

  ${MediaQ('768px')`
    flex-flow: row nowrap;
    align-items: flex-start;
  `};
`;

export const LoginBanner = styled.div`
    ${Flex('row nowrap', 'center', 'flex-start')};
    height: 25%;
    width: 100%
    background-color: #0092ff;
    padding: 0.75em;

    ${MediaQ('768px')`
      ${Flex('row nowrap', 'center', 'flex-start')};
      height: 100%;
      width: 45%;
      padding: 1em 2em;
    `};
`;

export const HeadingWrapper = styled.div`
  ${Flex('column nowrap', 'center', 'center')};
  height: 100%;
  width: 100%;
  margin: 1em 0;

  ${MediaQ('768px')`
    justify-content: flex-start;
    padding-top: 2em;
  `};
`;

export const ImageWrapper = styled.div`
  display: none;

  ${MediaQ('768px')`
    display: block;
    align-self: center;
    position: absolute;
    bottom: 0;
  `}
`;

export const LoginHeading = styled.h2`
  color: #ffffff;
  margin: 0;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.5;

  ${MediaQ('600px')`
    font-size: 2.25rem;
  `};
`;

export const LoginSubHeading = styled.h3`
  color: #ffffff;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;

  ${MediaQ('600px')`
    font-size: 1.25rem;
  `};
`;

export const LoginBody = styled.div`
  ${Flex('column nowrap', 'center', 'flex-start')};
  height: 75%;
  width: 100%;
  padding: 3em 2em;

  ${Button} {
    height: 55px;
    width: 250px;
    margin: 0 calc((100% - 250px) / 2);
  }

  ${MediaQ('768px')`
    ${Flex('row nowrap', 'center', 'flex-start')};
    height: 100%;
    width: 55%;


  `};
`;

export const LoginForm = styled.form`
  ${Flex('column nowrap', 'center', 'space-around')};
  height: 100%;
  width: 100%;
`;

export const SignUpWrapper = styled.div`
  font-size: 1.25rem;
  span {
    color: #0092ff;
    corsor: pointer;
  }

  ${MediaQ('600px')`
    font-size: 1.5rem;
  `};
`;

export const ForgotPassword = styled.div`
  color: #0092ff;
  cursor: pointer;
  font-size: 1.25rem;
  line-height: 2.5;
`;

export const LoginInput = styled(Input)`
  border: 1px solid transparent;
  border-bottom: 1px solid #2e2e2e;
  border-radius: 0px;
  color: #2e2e2e !important;
  width: 100%;
  height: 50px;
  font-weight: 500;
  font-size: 1.25rem;
`;

export const Icon = styled.img`
  height: 180px;
  width: 210px;
  vertical-align: middle;
`;
