import styled from 'styled-components';
import { MediaQ } from './utils';

export const Container = styled.div`
    width: calc(100% - 8px);
    height: 100%;
    margin: 0 auto;
    padding: 0;
    overflow-x: hidden;
    ${MediaQ('600px')`
        width: calc(100% - 2rem);
    `}
    @media screen and (min-width: 768px) {
        width: 95%;
    }
    @media screen and (min-width: 1025px) {
        width: 85%;
    }
    @media screen and (min-width: 1440px) {
        width: 80%;
    }
`;

export const Page = styled.main`
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
`;

export const SectionWrapper = styled.section`
    ${props => {
        if (props.fullHeight === 'true') {
            return 'height: 100vh';
        } else if (props.fullHeight === undefined) {
            return props.height ? `height: ${props.height}` : 'height: auto';
        }
    }};
    width: 100%;
    ${props =>
        props.backgroundImage && `background-image: ${props.backgroundImage}`};
    background-color: ${props =>
        props.bgColor ? props.bgColor : 'transparent'};
`;
