export const events = {
  1: "Supplier Mobile Register",
  2: "Supplier Login Creation",
  3: "GST Form Loaded",
  4: "GST Verified",
  5: "GST Submitted",
  6: "Pickup Address Loaded",
  7: "Pickup Address Submitted",
  8: "Bank Details Loaded",
  9: "Bank Details Submitted",
  10: "Supplier Details Loaded",
  11: "Supplier Details Submitted",
  12: "Page View",
}