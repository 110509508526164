import 'react-app-polyfill/stable';
import React, { useState,useLayoutEffect,useReducer,createContext } from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { Provider } from 'react-redux';
import { createGlobalStyle, ThemeProvider } from "styled-components";
// import * as serviceWorker from "./serviceWorker";
import theme from "./theme";
import AppRouter from "./router";
import "./fonts/style.css";
import "sanitize.css";
import "typeface-roboto";
import "typeface-lato";
import "typeface-barlow-condensed";
import "typeface-montserrat";
import "typeface-open-sans";
import * as Sentry from "@sentry/browser";
import { IS_PRODUCTION } from "./constants";
import {store} from './store';
import "./utils/modernizr.js";
import "./app.generated.css";
import * as serviceWorker from './serviceWorker';

const GlobalStyle = createGlobalStyle`
    @font-face {
      font-family: 'Avenir Roman';
      src: url("/react-images/Avenir-Roman.otf") format("opentype");
    }
    @font-face {
      font-family: 'BebasNeue Bold';
      src: url("/react-images/BebasNeue Bold.ttf") format("truetype");
    }

    @font-face {
        font-family: 'AbsolutPro';
        src: url("/react-images/Absolut_Pro_Bold_Italic_reduced.ttf") format("truetype");
    }

    @font-face {
      font-family: 'AzoSans-Black';
      src: url("/react-images/AzoSans-Black.ttf") format("truetype");
    }

    @font-face {
      font-family: 'Bookman Old Style Bold';
      src: url("/react-images/BOOKOSB.TTF") format("truetype");
    }

    * {
        box-sizing: border-box;
        font-family: "Lato",sans-serif;
        font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }

    html {
        font-size: 16px;
        height: 100%;
        width: 100%;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        margin: 0;
        padding: 0;
        -ms-text-size-adjust:100%;
        -webkit-text-size-adjust:100%
    	overflow-x: hidden;
    }

    body {
        font-size: 1rem;
        line-height: 1.618;
        position: relative;
        overflow-x: hidden;
        margin:0;
        padding:0;
        -webkit-overflow-scrolling: auto;
    }

    body .supplierDashboard {
      font-size: 14px;
    }

    html, body {
        font-family: Lato,Helvetica Neue;
        height: 100%;
        line-height: 1.618;
        margin: 0;
        padding: 0;
        width: 100%;
        overflow: initial;
    }

    body.branch-banner-is-active {
        margin-top: 0 !important;
        transition: none !important;
    }


    a {
        text-decoration: none;
        color: #ffffff;
    }

    #portalRoot {
        position: relative;
    }
    #root {
        position: relative;
        min-height: 100vh;
        width: 100%;
    }

    .fade-in-enter {
       opacity: 0;
       transform: scale(0.95);
       transform-origin: center top;
       transition: all 500ms ease-in-out;
    }

    .fade-in-enter-active {
        opacity: 1;
       transform: scale(1);
       transition: all 500ms ease-in-out;
        
    }
    .fade-in-enter-done {
        transition: all 0ms linear;
    }
    .fade-in-exit {
        opacity: 0;
       transform: scale(0);
       display: none;
       
       
    }
    .fade-in-exit-active {
        opacity: 0;
       transform: scale(0);
       display: none;
       transition: all 0ms linear;
    }

    iframe#branch-banner-iframe {
        display: none;
        opacity: 0;
        transform: scale(0);
        background: none;
    }
    iframe#branch-banner-iframe > * {
        display: none;
        background: none;
        background-color: none;
    }

    /* Spinning Wheel */

    :root {
      --wheel-font: "Lato", "Quicksand", sans-serif;
      --wheel-size: 310px;
      --wheel-slice-spacing: 50px;
      --wheel-border-size: 5px;
      --wheel-color: #f2f2f2;
      --neutral-color: white;
      --PI: 3.14159265358979;
      --nb-item: 0;
      --item-nb: 0;
      --selected-item: 0;
      --nb-turn: 5;
      --spinning-duration: 4s;
      --reset-duration: 0.25s;
    }
    
    .wheel-main {
      text-align: center;
    }
    .wheel-container {
      display: block;
      position: relative;
      box-sizing: content-box;
      width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
      height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
      padding: 3px;
      margin: auto;
      background-color: var(--neutral-color);
      /* border: solid var(--wheel-color) 3px; */
      border-radius: 50%;
      user-select: none;
    }
    
    /* .wheel-container::before,
    .wheel-container::after {
      content: "";
      display: block;
      position: absolute;
      height: 0;
      width: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      border: solid transparent 20px;
      border-left-width: 0;
    } */
    
    /* .wheel-container::before {
      right: 0px;
      border-right-color: var(--wheel-color);
    } */
    
    .wheel-container::after {
      right: -5px;
      border-right-color: var(--neutral-color);
    }
    
    .wheel {
      display: block;
      position: relative;
      box-sizing: content-box;
      margin: auto;
      width: var(--wheel-size);
      height: var(--wheel-size);
      overflow: hidden;
      border-radius: 50%;
      border: solid var(--wheel-color) var(--wheel-border-size);
      background-color: var(--wheel-color);
      transition: transform var(--reset-duration);
      transform: rotate(0deg);
      cursor: pointer;
    }
    
    .wheel.spinning {
      transition: transform var(--spinning-duration);
      transform: rotate(
        calc(
          var(--nb-turn) * 360deg +
            (-360deg * var(--selected-item) / var(--nb-item, 1))
        )
      );
    }
    
    .wheel::after {
      display: block;
      position: absolute;
      content: "";
      background-color: var(--neutral-color);
      width: 25px;
      height: 25px;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
    
    .wheel-item {
      display: block;
      position: absolute;
      box-sizing: border-box;
      font-size: 12px;
    
      top: 50%;
      left: 50%;
      width: 50%;
      transform-origin: center left;
      transform: translateY(-50%)
        rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));
    
      color: var(--neutral-color);
      text-align: right;
      padding: 0 30px 0 50px;
      font-family: var(--wheel-font);
    }
    
    .wheel-item:before {
      content: " ";
      display: block;
      position: absolute;
      box-sizing: border-box;
      z-index: -1;
      width: 0;
      height: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-left: 0px;
      /* opacity: 0.25; */
    
      --slice-max-width: calc(var(--PI) * var(--wheel-size) + var(--wheel-size));
      --slice-width: calc(
        (var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing)
      );
      border: solid transparent calc(var(--slice-width) / 2);
      border-left: solid transparent 0;
      border-right: solid var(--neutral-color) calc(var(--wheel-size) / 2);
    }
    
    .wheel-item:nth-of-type(1):before {
      border-right: solid#e65051 calc(var(--wheel-size) / 2);
    }
    
    .wheel-item:nth-of-type(2):before {
      border-right: solid#fca629 calc(var(--wheel-size) / 2);
    }
    
    .wheel-item:nth-of-type(3):before {
      border-right: solid #2fb074 calc(var(--wheel-size) / 2);
    }
    
    .wheel-item:nth-of-type(4):before {
      border-right: solid #3aa2e8 calc(var(--wheel-size) / 2);
    }
    
    .wheel-item:nth-of-type(5):before {
      border-right: solid #cb3d77 calc(var(--wheel-size) / 2);
    }
    
    .wheel-item:nth-of-type(6):before {
      border-right: solid #fca629 calc(var(--wheel-size) / 2);
    }
    
    .wheel-item:nth-of-type(7):before {
      border-right: solid #f3f3e9 calc(var(--wheel-size) / 2);
    }
    .wheel-item:nth-of-type(7) {
      color: #000;
    }
    
    .wheel-item:nth-of-type(8):before {
      border-right: solid #2fb074 calc(var(--wheel-size) / 2);
    }
    
    .center-pin {
      position: absolute;
      z-index: 10;
      width: 75px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
    .outer-ring {
      position: absolute;
      z-index: 10;
      width: 325px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
    .spin-button {
      margin-top: 24px;
      padding: 9px 16px;
      border-radius: 4px;
      background-color: #e75352;
      color: #fff;
      text-align: center;
      font-weight: 700;
    }
    .spin-button:focus {
      outline: none;
    }
    
    .winSection {
      min-height: 151px;
    }
`;

export const AppContext = createContext({
  isWebp: true,
  pwChange: false,
  email: "",
  loading: false,
});


const AppContextProvider = ({ reducer, initialState, children }) => (
  <AppContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </AppContext.Provider>
);

const reducer = (state, action) => {
  switch (action.type) {
    case "IS_SUPPORTED":
      return {
        ...state,
        isWebp: true,
      };
    case "IS_NOT_SUPPORTED":
      return {
        ...state,
        isWebp: false,
      };
    case "VALID_PW":
      return {
        ...state,
        pwChange: false,
      };
    case "INVALID_PW":
      return {
        ...state,
        pwChange: true,
      };
    case "SET_EMAIL":
      return {
        ...state,
        email: action.email,
      };
    case "RESET_EMAIL":
      return {
        ...state,
        email: "",
      };
    case "IS_LOADING":
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

const App = (props) => {
  const [isWebp, setIsWebp] = useState(false);

  const initialState = {
    isWebp, 
    pwChange: false,
    email: "",
    loading: false,
  }

  useLayoutEffect(() => {
    if (window.Modernizr) {
      window.Modernizr.on("webp", function (result) {
        if (result) {
          setIsWebp(true);
        } else {
          setIsWebp(false);
        }
      });
    }
  }, []);

  useState(() => {
    const GA_KEY = "UA-62438157-1";
    // ReactGA.initialize(GA_KEY, {
    //   gaOptions: { siteSpeedSampleRate: 100 },
    // });
    ReactGA.initialize(GA_KEY);

    document.addEventListener("readystatechange", (e) => {
      ReactGA.event({
        category: "React Web",
        label: document.readyState,
        action: "Page Load",
        nonInteraction: true,
      });
    });
  });

  return (
    <ThemeProvider theme={theme}>
      <AppContextProvider initialState={initialState} reducer={reducer}>
        <Provider store={store}>
          <AppRouter isWebpSupported={isWebp} />
          <GlobalStyle />
        </Provider>
      </AppContextProvider>
    </ThemeProvider>
  );
};

const appRoot = document.getElementById("root");

const config = {
  dsn: IS_PRODUCTION
    ? "https://3f8823d4c49840abb176fc44c90b352a@sentry.io/1456014"
    : "",
};

if (appRoot) {
  ReactDOM.render(<App />, appRoot);
  if (IS_PRODUCTION) {
    Sentry.init(config);
  }
}

serviceWorker.register({
  onUpdate: registration => {
    console.log('* => On service worker update called....');
    if (registration && registration.waiting) {
      console.log('* => Registration waiting...', JSON.stringify(registration));
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  },
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
