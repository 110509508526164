import styled from 'styled-components';
import { Flex, MediaQ } from './utils';

export const Input = styled.input`
    display: block;
    height: ${props =>
        props.theme.layout.buttonHeight
            ? props.theme.layout.buttonHeight
            : '36px'};
    width: ${props => (props.fullWidth ? '100%' : 'auto')};
    border: 1px solid ${props => props.theme.colors.borderColor};
    border-radius: ${props =>
        props.theme.layout.borderRadius
            ? props.theme.layout.borderRadiius
            : '6px'};
    line-height: ${props =>
        props.theme.layout.buttonHeight
            ? props.theme.layout.buttonHeight
            : '36px'};
    padding: 0 0.5rem;
    color: ${props => props.theme.colors.textLight};
    overflow: hidden
    word-wrap: normal;
    text-overflow: ellipsis;
    white-space: nowrap
`;

export const FormInput = styled(Input)`
    border-radius: 3px;
    width: 100%;
    height: 3rem;
    line-height: normal;
    border: 2px solid ${props => props.theme.colors.borderColor};
    color: #2e2e2e;
    padding: 0 42px 0 1rem;
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    &:focus {
        border: 2px solid ${props => props.theme.colors.primary};
        outline: none;
    }
`;

export const Label = styled.label`
    font-size: 0.88rem;
    font-weight: 700;
    color: #2e2e2e;
    display: block;
    line-height: 1.618;
    margin: 0;
    padding: 0;
`;

export const CheckBoxGroup = styled.div`
    ${Flex('row wrap', 'center', 'flex-start')};
`;

export const CheckBoxField = styled.div`
    min-width: 33%;
    margin: 0.5rem 0;
`;

export const Checkmark = styled.span``;

export const CheckBox = styled.input.attrs({
    type: 'checkbox',
})`
    position: absolute;
    opacity: 0;

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        user-select: none;
    }

    & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 16px;
        height: 16px;
        background: white;
        border: 2px solid ${props => props.theme.colors.primary};
        border-radius: 3px;
    }
    &:hover + label:before {
        background: ${props => props.theme.colors.backgroundColor};
    }

    &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    &:checked + label:before {
        background: white;
    }

    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    &:checked + label:after {
        content: '';
        position: absolute;
        left: 6px;
        top: 6px;
        background: white;
        width: 4px;
        height: 9px;
        border-bottom: 2px solid ${props => props.theme.colors.primary};
        border-right: 2px solid ${props => props.theme.colors.primary};
        transform: rotate(45deg);
    }
`;

export const LabelContainer = styled(Label)``;

export const FormSelectWrapper = styled.div`
    position: relative;
`;

export const FormSelectToggle = styled.div`
    position: absolute;
    top: calc(50% - 4px);
    right: 8px;
    height: 0;
    width: 0;
    border: 8px solid transparent;
    border-top: 8px solid rgba(0, 0, 0, 0.25);
    pointer-events: none;
`;

export const FormSelect = styled.select`
    appearance: none;
    display: block;
    height: 3rem;
    line-height: normal;
    border: 2px solid ${props => props.theme.colors.borderColor};
    width: 100%;
    padding: 0 0.5rem;
    outline: none;
    color: rgba(0, 0, 0, 0);
    color: #2e2e2e;
    text-shadow: 0 0 0 #000;
    &:-moz-focusring {
        color: transparent;
        color: #2e2e2e;
        text-shadow: 0 0 0 #000;
        background-color: transparent;
    }
`;

export const RadioBoxField = styled.div`
    width: 50%;
    margin-bottom: 0.5rem;
    ${MediaQ('1024px')`
        width: 33.33%;
        margin-bottom: 0;
    `}
`;

export const RadioBox = styled.input.attrs({
    type: 'radio',
})``;

export const RadioBoxGroup = styled.div`
    ${Flex('row wrap', 'center', 'flex-start')};

    ${MediaQ('1024px')`
        flex-wrap: nowrap;
        justify-content: space-between;
    `}

    [type='radio']:checked,
    [type='radio']:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #2e2e2e;
    }
    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 2px solid ${props => props.theme.colors.primary};
        border-radius: 100%;
        background: #fff;
    }
    [type='radio']:checked + label:after,
    [type='radio']:not(:checked) + label:after {
        content: '';
        background: ${props => props.theme.colors.primary};
        position: absolute;
        width: 8px;
        height: 8px;
        top: 5px;
        left: 5px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type='radio']:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type='radio']:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
`;

export const ValidationError = styled.p`
    color: #cc0000;
    padding: 0;
    margin: 0.25rem 0 0;
    font-size: 14px;
    display: none;
`;

export const FormGroup = styled.fieldset`
    width: 100%;
    height: auto;
    padding: 0;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    border: none;
    .rbt-menu > li a {
        width: 100%;
        display: block;
    }
    & > input[type='email']:invalid {
        border: 2px solid red;
        outline: none;
        box-shadow: none;
        background-image: url(https://d1ixo36kppfedg.cloudfront.net/web/images/home/close.svg);
        background-position: calc(100% - 12px) center;
        background-size: 20px;
        padding: 0 42px 0 1rem;
    }
    &.invalid {
        ${FormInput},input.rbt-input-main.form-control.rbt-input,${FormSelect} {
            border: 2px solid red;
            outline: none;
            box-shadow: none;
            background-image: url(https://d1ixo36kppfedg.cloudfront.net/web/images/home/close.svg);
            background-position: calc(100% - 12px) center;
            background-size: 20px;
            padding: 0 42px 0 1rem;
        }
        ${FormSelect} {
            padding: 0 48px 0 1rem;
            background-position: calc(100% - 36px) center;
        }
        ${ValidationError} {
            display: block;
        }
    }
    &.valid {
        ${FormInput},${FormSelect},input.rbt-input-main.form-control.rbt-input {
            border: 2px solid #4bb543;
            outline: none;
            box-shadow: none;
            background-image: url(https://d1ixo36kppfedg.cloudfront.net/web/images/home/tick.svg);
            background-position: calc(100% - 12px) center;
            background-size: 20px;
            padding: 0 42px 0 1rem;
        }
        ${FormSelect} {
            padding: 0 48px 0 1rem;
            background-position: calc(100% - 36px) center;
        }
        ${ValidationError} {
            display: none;
        }
    }
`;
