import React, { Suspense, lazy, useCallback, useEffect, useRef, useState, useContext, useLayoutEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { debounce } from "debounce";
import ReactGA from "react-ga";
import { v4 as uuidv4 } from 'uuid';
import Header from "components/Header";
import NewLoader from "components/NewLoader";
import Loader from "components/Loader/index";
// import SupplierLoader from 'components/SupplierDashboard/SupplierLoader';
import { AppContext } from "..";
import { handleNavigationInteraction, retry, useAppDataHook, useLocalStorage, fetchData } from "utils";
import { sendEvent } from "utils/eventCapture";
import { events } from "constants/events";
import { BASE_URL, JSON_HEADER } from "constants/index";
import RouteComponent from "./RouteComponent";
import ShopFooter from "components/ShopFooter";
export const history = createBrowserHistory();

const ComplaintsForOrders = lazy(() =>
  retry(() => import("screens/ComplaintsForOrders"))
);

const PartnerProducts = lazy(() =>
  retry(() => import("screens/PartnerProducts"))
);
const PartnerProductBrands = lazy(() =>
  retry(() => import("screens/PartnerProductBrands"))
);
const PartnerProductBrandCatalog = lazy(() =>
  retry(() => import("screens/PartnerProductBrandCatalog"))
);

const Home = lazy(() => retry(() => import("screens/Home")));
const BridesSarees = lazy(() => retry(() => import("screens/BridesSarees")));
const Supplier = lazy(() => retry(() => import("screens/Supplier")));
// const BDOnboarding = lazy(() => retry(() => import("screens/BDOnboarding")));
const Leaderboard = lazy(() => retry(() => import("screens/Leaderboard")));
const PageNotFound = lazy(() => retry(() => import("screens/PageNotFound")));
const UnauthorizedPage = lazy(() => retry(() => import("screens/UnauthorizedPage")));
const YearEndSale = lazy(() => retry(() => import("screens/YearEndSale")));
const TestimonialsContest = lazy(() =>
  retry(() => import("screens/TestimonialsContest"))
);
const About = lazy(() => retry(() => import("screens/About")));
const MobileRecharge = lazy(() =>
  retry(() => import("screens/NewMobileRecharge"))
);
const MobileRechargeStatus = lazy(() =>
  retry(() => import("screens/MobileRechargeStatus"))
);
// const Membership = lazy(() => retry(() => import("screens/Membership")));
const ReferAndEarn = lazy(() => retry(() => import("screens/ReferAndEarn")));
const TermsAndConditions = lazy(() =>
  retry(() => import("screens/TermsAndConditions"))
);
const PostMembershipPurchase = lazy(() =>
  retry(() => import("screens/PostMembershipPurchase"))
);
const ManageMembership = lazy(() => import("screens/ManageMembership"));
const CancelAutoRenewal = lazy(() => import("screens/CancelAutoRenewal"));
// const IPLContest = lazy(() => import("screens/IPLContest"));
const DiwaliContest = lazy(() => import("screens/DiwaliContest"));
const LotteryContest = lazy(() => import("screens/LotteryContest"));
const Referral = lazy(() => import("screens/Referral"));
const BrandedDeals = lazy(() => import("screens/BrandedDeals"));
const SalesSignup = lazy(() => import("screens/SalesSignup"));
const SalesSignupStatus = lazy(() => import("screens/SalesSignupStatus"));
const ResellerShoppingFest = lazy(() =>
  retry(() => import("screens/ResellerShoppingFest"))
);

const InternationalJewellerySale = lazy(() =>
  retry(() => import("screens/InternationalJewellerySale"))
);
const WinterWearSale = lazy(() =>
  retry(() => import("screens/WinterWearSale"))
);
const WeddingSale = lazy(() => retry(() => import("screens/WeddingSale")));
const DiwaliSale = lazy(() => retry(() => import("screens/DiwaliSale")));
const DiwaliBonusSale = lazy(() =>
  retry(() => import("screens/DiwaliBonusSale"))
);
const TestPage = lazy(() => retry(() => import("screens/TestPage")));
const DiwaliCashbackSale = lazy(() =>
  retry(() => import("screens/DiwaliCashbackSale"))
);
const NewPlatinumTnC = lazy(() => 
  retry(() => import("screens/NewPlatinumTnC"))
);
const ChildrensDaySale = lazy(() =>
  retry(() => import("screens/ChildrensDaySale"))
);
const ReferralContest = lazy(() =>
  retry(() => import("screens/ReferralContest"))
);
const SareesSale = lazy(() => retry(() => import("screens/SareesSale")));
const PaytmCashbackOffer = lazy(() =>
  retry(() => import("screens/PaytmCashbackOffer"))
);
const FBMarketPlace = lazy(() =>
  retry(() => import("screens/FBMarketPlace"))
);
const SironaSale = lazy(() => retry(() => import("screens/SironaSale")));
const EthnicWearSale = lazy(() =>
  retry(() => import("screens/EthnicWearSale"))
);
const PlatinumReferral = lazy(() => import("screens/PlatinumReferral"));
const DiwaliDhamakaWinnersTestimonial = lazy(() =>
  retry(() => import("screens/DiwaliDhamakaWinnersTestimonial"))
);
const BeautyFestival = lazy(() =>
  retry(() => import("screens/BeautyFestival"))
);

const CashbackMegaSaleWeek = lazy(() =>
  retry(() => import("screens/CashbackMegaSaleWeek"))
);
const BridalJewelleryStore = lazy(() =>
  retry(() => import("screens/BridalJewelleryStore"))
);
const HumSaathSaathHain = lazy(() =>
  retry(() => import("screens/HumSaathSaathHain"))
);
const KidsWinterWearStyle = lazy(() =>
  retry(() => import("screens/KidsWinterWearStyle"))
);
const XmasSuperSavings = lazy(() =>
  retry(() => import("screens/XmasSuperSavings"))
);
const ComingSoon = lazy(() => retry(() => import("screens/ComingSoon")));
const FatherAndSon = lazy(() => retry(() => import("screens/FatherAndSon")));
const HarvestFestival = lazy(() =>
  retry(() => import("screens/HarvestFestival"))
);
const RepublicDayKidsSale = lazy(() =>
  retry(() => import("screens/RepublicDayKidsSale"))
);
const ShubhAarambh = lazy(() => retry(() => import("screens/ShubhAarambh")));
const PremiumStore = lazy(() => retry(() => import("screens/PremiumStore")));
const ValentinesDaySale = lazy(() =>
  retry(() => import("screens/ValentinesDaySale"))
);
const LuckyDrawContest = lazy(() =>
  retry(() => import("screens/LuckyDrawContest"))
);
const LuckyResellerContest = lazy(() =>
  retry(() => import("screens/LuckyResellerContest"))
);
const PlatinumMembership = lazy(() =>
  retry(() => import("screens/PlatinumMembership"))
);

const GajabDiscountDays = lazy(() =>
  retry(() => import("screens/GajabDiscountDays"))
);

const GajabDiscountDays12am = lazy(() =>
  retry(() => import("screens/GajabDiscountDays12am"))
);

const GajabDiscountDays10th = lazy(() =>
  retry(() => import("screens/GajabDiscountDays10th"))
);

const GajabDiscountDays11th = lazy(() =>
  retry(() => import("screens/GajabDiscountDays11th"))
);

const GajabDiscountDays12th = lazy(() =>
  retry(() => import("screens/GajabDiscountDays12th"))
);

const GajabDiscountDays13th = lazy(() =>
  retry(() => import("screens/GajabDiscountDays13th"))
);

const AdminPanels = lazy(() =>
  retry(() => import("screens/AdminPanels"))
);

const Privacy = lazy(() =>
  retry(() => import("screens/Privacy"))
);

const ResellerTermsAndConditions = lazy(() =>
  retry(() => import("screens/ResellerTermsAndConditions"))
);

const AdditionalResellerTermsAndCondition = lazy(() =>
  retry(() => import("screens/AdditionalResellerTermsAndCondition"))
);

const NewResellerTermsAndConditions = lazy(() =>
  retry(() => import("screens/NewResellerTermsAndConditions"))
);

const TCsBestPriceChallenge = lazy(() =>
  retry(() => import("screens/TCsBestPriceChallenge"))
);

const SupplierTCsBestPriceChallenge = lazy(() =>
  retry(() => import("screens/SupplierTCsBestPriceChallenge"))
);

const SupplierVerification = lazy(() =>
  retry(() => import("screens/SupplierVerification"))
);

const SupplierDashboard = lazy(() =>
  retry(() => import("screens/SupplierDashboard"))
);

const SupplierTermsAndCondition = lazy(() =>
  retry(() => import("screens/SupplierTermsAndCondition"))
);

const GetAppLink = lazy(() =>
  retry(() => import("screens/GetAppLink"))
);

const SupplierRewardProgramTnC = lazy(() =>
  retry(() => import("screens/SupplierRewardProgramTnC"))
);

const RewardsTnC = lazy(() =>
  retry(() => import("screens/RewardsTnC"))
);


const NewRewardsTnC = lazy(() =>
  retry(() => import("screens/NewRewardsTnC"))
);

const PrepayRewardsTnC = lazy(() =>
  retry(() => import("screens/PrepayRewardsTnC"))
);


const RewardsFAQ = lazy(() =>
  retry(() => import("screens/RewardsFAQ"))
);

const DeleteAccount = lazy(() =>
  retry(() => import("screens/DeleteAccount"))
);

const PlatinumMarketingFees = lazy(() =>
  retry(() => import("screens/PlatinumMarketingFees"))
);

const verifyTotp = async (token,totp) => {
  let URL = `${BASE_URL}/user/verifyTotp`;
  try {
    let res = await fetchData(URL, {
      headers: JSON_HEADER,
      method: "POST",
      body: JSON.stringify({ token, totp }),
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

const AppRouter = (props) => {
  const [isFixed, setIsFixed] = useState(false);
  const [webview, setWebiew] = useState(true);
  const [showFooter, setShowFooter] = useState(false);
  const [{loading}, dispatchContext] = useContext(AppContext);
  const { noLoader, user_auth_token, user_tmp_param1, user_tmp_param2, version } = useAppDataHook(history.location);
  const navbar = useRef(null);
  const supplierForm = useRef(null);
  const firstFormInput = useRef(null);
  const form = useRef(null);
  const { get: getStorageItem, set: setStorageItem } = useLocalStorage();
  const hiring = useRef(null);

  const [isSpecialNavbar, setIsSpecialNavbar] = useState(false);

  useLayoutEffect(() => {
    const { pathname } = window.location;

    console.log(pathname);
    const isWebView =
      pathname.match(/\/webviews\/contactus\/complaints/) ||
      pathname.match(/\/contests\//) ||
      pathname.match(/\/gajab-discount-days/) ||
      pathname.match(/\/gajab-discount-days-13th/) ||
      pathname.match(/\/gajab-discount-days-12th/) ||
      pathname.match(/\/gajab-discount-days-11th/) ||
      pathname.match(/\/gajab-discount-days-10th/) ||
      pathname.match(/\/gajab-discount-days-12am/) ||
      pathname.match(/\/testimonials-contest/) ||
      pathname.match(/\/mobile-recharge/) ||
      pathname.match(/\/mobile-recharge-status/) ||
      pathname.match(/\/referandearn/) ||
      pathname.match(/\/membership/) ||
      pathname.match(/\/reseller-shopping-fest/) ||
      pathname.match(/\/wedding-sale/) ||
      pathname.match(/\/diwali-sale/) ||
      pathname.match(/\/diwali-bonus-sale/) ||
      pathname.match(/\/diwali-cashback-sale/) ||
      pathname.match(/\/test-page/) ||
      pathname.match(/\/platinum-tnc/) ||
      pathname.match(/\/platinum-marketing-fees/) ||
      pathname.match(/\/childrens-day-sale/) ||
      pathname.match(/\/international-jewellery-sale/) ||
      pathname.match(/\/winter-wear-sale/) ||
      pathname.match(/\/sarees-sale/) ||
      pathname.match(/\/paytmtc/) ||
      pathname.match(/\/cashback-mega-sale-week/) ||
      pathname.match(/\/paytm-cashback-offer/) ||
      pathname.match(/\/bridal-jewellery-store/) ||
      pathname.match(/\/happy-period-days/) ||
      pathname.match(/\/referral-contest/) ||
      pathname.match(/\/hum-saath/) ||
      pathname.match(/\/fb-marketplace/) ||
      pathname.match(/\/post-membership-purchase/) ||
      pathname.match(/\/manage-membership/) ||
      pathname.match(/\/cancel-auto-renewal/) ||
      pathname.match(/\/platinum-referral/) ||
      pathname.match(/\/kids-winter-wear-style/) ||
      pathname.match(/\/xmas-super-savings/) ||
      pathname.match(/\/coming-soon/) ||
      pathname.match(/\/father-and-son/) ||
      pathname.match(/\/year-end-sale/) ||
      pathname.match(/\/brides-we-love/) ||
      pathname.match(/\/get-party-ready/) ||
      pathname.match(/\/diwali-dhamaka-winners-testimonial/) ||
      pathname.match(/\/beauty-festival-2020/) ||
      pathname.match(/\/harvest-festival/) ||
      pathname.match(/\/republic-day-sale-for-kids/) ||
      pathname.match(/\/shubh-aarambh/) ||
      pathname.match(/\/premium-store/) ||
      pathname.match(/\/valentines-day-sale/) ||
      pathname.match(/\/lucky-reseller-contest/) ||
      pathname.match(/\/lucky-draw-contest/) ||
      pathname.match(/\/platinum-membership/) ||
      pathname.match(/\/partner-products/) ||
      pathname.match(/\/partner-product-brands/) ||
      pathname.match(/\/partner-product-brand-catalog/) ||
      pathname.match(/\/ipl-contest/) ||
      pathname.match(/\/diwali-contest/) ||
      pathname.match(/\/lottery-contest/) ||
      pathname.match(/\/webviews\/branded-deals/) ||
      pathname.match(/\/referral/) ||
      pathname.match(/\/403/);

    const noHeader =
      pathname.match(/\/supplier-dashboard/) ||
      pathname.match(/\/supplier-dashboard\/verification/) ||
      pathname.match(/\/get-app-link/) ||
      pathname.match(/\/panel/) ||
      pathname.match(/\/sales-signup/) ||
      pathname.match(/\/sales-signup-status/) ||
      pathname.match(/\/gr-az\/tnc/) ||
      pathname.match(/\/privacy/) ||
      pathname.match(/\/termsandconditions/) ||
      pathname.match(/\/TermsAndConditions/) ||
      pathname.match(/\/additionaltermsandconditions/) ||
      pathname.match(/\/AdditionalTermsAndConditions/) ||
      pathname.match(/\/resellertermsandconditions/) ||
      pathname.match(/\/tnc-best-price-challenge/) ||
      pathname.match(/\/supplier-tnc-best-price-challenge/) ||
      pathname.match(/\/delete-account/) ||
      pathname.match(/\/supplier-reward-program-tnc/) ||
      pathname.match(/\/rewards-tnc/) ||
      pathname.match(/\/new-rewards-tnc/) ||
      pathname.match(/\/pre-pay-rewards-tnc/) ||
      pathname.match(/\/rewards-faq/);
   

    if (pathname === "/about") {
      setIsSpecialNavbar(true);
    } else {
      setIsSpecialNavbar(false);
    }

    if (isWebView || noHeader) {
      if (navbar.current) {
        navbar.current.style.display = "none";
      }
      setWebiew(true);
    } else {
      setWebiew(false);
    }
    if(isWebView) {
      if(user_tmp_param1 && user_tmp_param2){
        // verifyTotp(user_tmp_param1,user_tmp_param2).then(res => {
        //   if(res?.result === "success") {
        //     console.log("Verification Successful");
        //   } else {
        //     history.push('/403');
        //   }
        // });
      } else if(!user_auth_token) {
        history.push('/403');
      }
    }
  }, [user_auth_token, user_tmp_param1,user_tmp_param2]);

  useEffect(() => {
    if(!webview) {
      setTimeout(() => {
        setShowFooter(true);
      },1000);
    }
  },[webview]);

  //Generate uuid for the user
  const generateUuid = () => {
    let uuid = getStorageItem('uuid');
    if (!uuid) {
      uuid = uuidv4();
      setStorageItem('uuid', uuid);
    }
  }

  const handleScrollBehavior = useCallback(
    (e) => {
      if (window.pageYOffset > 5) {
        setIsSpecialNavbar(true);
        if (!isFixed) {
          setIsFixed(true);
        }
      } else {
        setIsSpecialNavbar(false);
        setIsFixed(false);
      }
    },
    [isFixed]
  );

  useEffect(() => {
    if (props.isWebpSupported) {
      dispatchContext({ type: "IS_SUPPORTED" });
    } else {
      dispatchContext({ type: "IS_NOT_SUPPORTED" });
    }
  }, [dispatchContext, props.isWebpSupported]);

  useEffect(() => {
    // console.log(noLoader, "noLoader bool");
    if (navbar.current) {
      window.addEventListener("scroll", debounce(handleScrollBehavior, 5));
      window.addEventListener("touchmove", debounce(handleScrollBehavior, 5));
    }
    return () => {
      window.removeEventListener("scroll", handleScrollBehavior);
      window.removeEventListener("touchmove", handleScrollBehavior);
    };
  }, [handleScrollBehavior]);

  useEffect(() => {
    // const { pathname } = history.location;

    // ReactGA.set({ page: pathname });
    // ReactGA.send({ page: pathname });
    // ReactGA.pageview(pathname);
    if (window.localStorage !== undefined && window.localStorage !== null) {
      generateUuid();
    }
  }, []);

  const handleLocationChange = (location) => {
    const { pathname, search } = location;
    if(pathname !== '/supplier-dashboard/verification') {
      dispatchContext({ type: "SET_EMAIL", email: '' });
      window.localStorage.removeItem('userId');
    }
    ReactGA.pageview(pathname+search);
    sendEvent(events["12"],12,"VIEW","url",window.location.href);
    setShowFooter(false);
  }

  useEffect(() => {
    console.log("noLoader", noLoader);
  }, [noLoader]);

  const fixNavbar = () => {
    setIsFixed(true);
  };

  const handleNavigation = () => {
    const { pathname, origin } = window.location;

    if (pathname === "/supplier-onboarding") {
      if (window.innerWidth < 768) {
        if (form.current) {
          handleNavigationInteraction(form.current).then(() => {
            if (firstFormInput.current) {
              setTimeout(() => {
                firstFormInput.current.focus();
              }, 1000);
            }
          });
        }
      } else {
        if (supplierForm.current) {
          handleNavigationInteraction(supplierForm.current).then(() => {
            if (firstFormInput.current) {
              setTimeout(() => {
                firstFormInput.current.focus();
              }, 1000);
            }
          });
        }
      }
    } else if (pathname !== "/supplier-onboarding") {
      window.location = origin + "/supplier-onboarding";
    }
  };

  return (
    <Router history={history}>
      {/* Header Goes here */}
      <Header
        ref={navbar}
        handleNavigation={handleNavigation}
        //handleHiringNavigation={handleHiringNavigation}
        isFixed={isFixed}
        fixNavbar={fixNavbar}
        isSpecialNavbar={isSpecialNavbar}
      />
      {
        loading && (
          <NewLoader />
        )
      }
      <RouteComponent handleLocationChange={handleLocationChange} />
      <Suspense fallback={!noLoader && <Loader />}>
        <Switch>
          <Route component={Home} path="/how-to-earn" exact={true} />
          <Route path="/gajab-discount-days" component={GajabDiscountDays} />
          <Route
            path="/gajab-discount-days-12am"
            component={GajabDiscountDays12am}
          />
          <Route
            path="/gajab-discount-days-10th"
            component={GajabDiscountDays10th}
          />
          <Route
            path="/gajab-discount-days-11th"
            component={GajabDiscountDays11th}
          />
          <Route
            path="/gajab-discount-days-12th"
            component={GajabDiscountDays12th}
          />
          <Route
            path="/gajab-discount-days-13th"
            component={GajabDiscountDays13th}
          />
          <Route component={MobileRecharge} path="/mobile-recharge" />
          <Route component={MobileRechargeStatus} path="/mobile-recharge-status" />
          <Route
            path="/about"
            render={(props) => <About {...props} ref={hiring} />}
          />
          <Route
            path="/supplier"
            render={(props) => (
              <Supplier
                {...props}
                ref={{ supplierForm, firstFormInput, form }}
                handleNavigation={handleNavigation}
              />
            )}
          />
          <Route
            path="/supplier-onboarding"
            render={(props) => (
              <Supplier
                {...props}
                ref={{ supplierForm, firstFormInput, form }}
                handleNavigation={handleNavigation}
              />
            )}
          />
          <Route
            path="/sell"
            render={(props) => (
              <Supplier
                {...props}
                ref={{ supplierForm, firstFormInput, form }}
                handleNavigation={handleNavigation}
              />
            )}
          />
          <Route path="/contests/:path" component={Leaderboard} />
          <Route
            path="/platinum-membership"
            component={PlatinumMembership}
          ></Route>
          <Route path="/partner-products" component={PartnerProducts} />
          <Route
            path="/partner-product-brands/:partnerProductCategoryId"
            component={PartnerProductBrands}
          />
          <Route
            path="/partner-product-brand-catalog"
            component={PartnerProductBrandCatalog}
          />
          <Route path="/testimonials-contest" component={TestimonialsContest} />
          <Route path="/membership" component={PlatinumMembership} />
          <Route
            path="/post-membership-purchase"
            component={PostMembershipPurchase}
          />
          <Route path="/manage-membership" component={ManageMembership} />
          <Route path="/cancel-auto-renewal" component={CancelAutoRenewal} />
          <Route path="/platinum-tnc" component={NewPlatinumTnC} />
          <Route path="/supplier-reward-program-tnc" component={SupplierRewardProgramTnC} />
          <Route path="/rewards-tnc" component={RewardsTnC} />
          <Route path="/platinum-marketing-fees" component={PlatinumMarketingFees} />
          <Route path="/new-rewards-tnc" component={NewRewardsTnC} />
          <Route path="/pre-pay-rewards-tnc" component={PrepayRewardsTnC} />
          <Route path="/ipl-contest" component={BrandedDeals} />
          <Route path="/diwali-contest" component={DiwaliContest} />
          <Route path="/lottery-contest" component={LotteryContest} />
          <Route path="/webviews/branded-deals" component={BrandedDeals} />
          <Route path="/referral" component={Referral} />
          <Route path="/sales-signup" component={SalesSignup} />
          <Route path="/sales-signup-status" component={SalesSignupStatus} />
          <Route
            path="/reseller-shopping-fest"
            component={ResellerShoppingFest}
          />
          <Route path="/winter-wear-sale" component={WinterWearSale} />
          <Route
            path="/international-jewellery-sale"
            component={InternationalJewellerySale}
          />
          <Route path="/platinum-referral" component={PlatinumReferral} />
          <Route
            path="/kids-winter-wear-style"
            component={KidsWinterWearStyle}
          />
          <Route path="/coming-soon" component={ComingSoon} exact={true} />
          <Route path="/hum-saath" component={HumSaathSaathHain} />
          <Route path="/wedding-sale" component={WeddingSale} />
          <Route path="/diwali-sale" component={DiwaliSale} />
          <Route path="/diwali-bonus-sale" component={DiwaliBonusSale} />
          <Route path="/referandearn//:token" component={ReferAndEarn} />
          <Route path="/referandearn/:prop/:token" component={ReferAndEarn} />
          <Route path="/referandearn/:token" component={ReferAndEarn} />
          <Route path="/paytmtc" component={TermsAndConditions} />
          <Route path="/test-page" component={TestPage} />
          <Route path="/diwali-cashback-sale" component={DiwaliCashbackSale} />
          <Route path="/childrens-day-sale" component={ChildrensDaySale} />
          <Route path="/referral-contest" component={ReferralContest} />
          <Route path="/sarees-sale" component={SareesSale} />
          <Route path="/fb-marketplace" component={FBMarketPlace} />
          <Route path="/paytm-cashback-offer" component={PaytmCashbackOffer} />
          <Route path="/happy-period-days" component={SironaSale} />
          <Route path="/xmas-super-savings" component={XmasSuperSavings} />
          <Route path="/father-and-son" component={FatherAndSon} />
          <Route path="/year-end-sale" component={YearEndSale} />
          <Route path="/brides-we-love" component={BridesSarees} />
          <Route path="/get-party-ready" component={EthnicWearSale} />
          <Route path="/beauty-festival-2020" component={BeautyFestival} />
          <Route path="/harvest-festival" component={HarvestFestival} />
          <Route path="/shubh-aarambh" component={ShubhAarambh} />
          <Route path="/valentines-day-sale" component={ValentinesDaySale} />
          <Route path="/premium-store" component={PremiumStore} />
          <Route
            path="/lucky-reseller-contest"
            component={LuckyResellerContest}
          />
          <Route
            path="/lucky-draw-contest"
            component={LuckyDrawContest}
          ></Route>

          <Route
            path="/republic-day-sale-for-kids"
            component={RepublicDayKidsSale}
          />
          <Route
            path="/diwali-dhamaka-winners-testimonial"
            component={DiwaliDhamakaWinnersTestimonial}
          />
          <Route
            path="/bridal-jewellery-store"
            component={BridalJewelleryStore}
          ></Route>
          <Route
            path="/cashback-mega-sale-week"
            component={CashbackMegaSaleWeek}
          />
          <Route
            path="/bdonboarding/:bdEmail"
            // render={(props) => (
            //   <BDOnboarding
            //     {...props}
            //     ref={{ supplierForm, firstFormInput, form }}
            //   />
            // )}
            render={(props) => (
              <Supplier
                {...props}
                ref={{ supplierForm, firstFormInput, form }}
                handleNavigation={handleNavigation}
              />
            )}
          />
          <Route
            path="/webviews/contactus/complaints"
            exact={true}
            component={ComplaintsForOrders}
          ></Route>
          <Route
            path="/webviews/contactus/complaints/:orderId"
            component={ComplaintsForOrders}
          ></Route>
          <Route component={Privacy} path="/privacy" />
          <Route component={ResellerTermsAndConditions} path="/termsandconditions" />
          <Route component={AdditionalResellerTermsAndCondition} path="/additionaltermsandconditions" />
          <Route component={NewResellerTermsAndConditions} path="/resellertermsandconditions" />
          <Route component={TCsBestPriceChallenge} path="/tnc-best-price-challenge" />
          <Route component={SupplierTCsBestPriceChallenge} path="/supplier-tnc-best-price-challenge" />
          <Route component={RewardsFAQ} path="/rewards-faq" />
          <Route component={SupplierVerification} path="/supplier-dashboard/verification" />
          <Route component={SupplierDashboard} path="/supplier-dashboard/*" />
          <Route component={SupplierTermsAndCondition} path="/gr-az/tnc" />
          <Route component={GetAppLink} path="/get-app-link" />
          <Route component={DeleteAccount} path="/delete-account" />
          <Route component={AdminPanels} path="/panel/*" />
          <Route component={UnauthorizedPage} path="/403" />
          <Route exact path="/settings">
            <Redirect to="/" />
          </Route>
          <Route path="/user/resetPassword*">
            <Redirect to="/" />
         </Route>
          {/* 404 Route */}
          <Route component={PageNotFound} />
        </Switch>
      </Suspense>
      {/* {
        showFooter && (
          <ShopFooter />
        )
      } */}
    </Router>
  );
};

AppRouter.defaultProps = {};

export default AppRouter;
