import * as React from 'react';
import ReactDOM from 'react-dom';
import { ModalWrapper, ModalDialog, CloseButton, Icon } from './styles';
import { AppContext } from '../../index';
import { resolveImages } from '../../utils/index';

const Portal = ({ isOpen, children, onClose, styles, buttonStyles }) => {
    const modalRoot = document.getElementById('portalRoot');
    const [{ isWebp }] = React.useContext(AppContext);

    return isOpen
        ? ReactDOM.createPortal(
              <ModalWrapper>
                  <ModalDialog style={{ ...styles }}>
                      {children}

                      <CloseButton
                          buttonType="icon"
                          onClick={onClose}
                          style={buttonStyles}
                      >
                          <Icon
                              src={resolveImages(
                                  'https://d1ixo36kppfedg.cloudfront.net/web/images/home/close.png',
                                  isWebp
                              )}
                              alt="close"
                          />
                      </CloseButton>
                  </ModalDialog>
              </ModalWrapper>,
              modalRoot
          )
        : null;
};

Portal.defaultProps = {
    isOpen: false,
};

export default Portal;
