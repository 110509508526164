export const required = /^\s*$/;
// export const nameCheck = /^([\w]{3,})?(\s+[\w]{1,})?$/;
export const nameCheck = /^\w{3,}(\s{0,1}\w*)*$/;
export const mobileCheck = /^\d{10}$/;
export const pinCheck = /^\d{6}$/;
export const emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const address = /^[^@!#$%&^*()<>?/\\/?`?|}{~:;+=]*$/;
export const password = /^.{8,32}$/;
export const otpCheck = /^\d{6}$/;
export const yTLinkCheck = /^https:\/\/www\.youtube\.com\/(watch\?v=|embed\/)/;
export const cloudFrontLinkCheck = /^https:\/\/(.*)\.cloudfront\.net\//;
export const timeFormatCheck = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/;
export const floatCheck = /^(\d*.?\d+)?$/;
export const integerCheck = /^(\d+)?$/;