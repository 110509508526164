import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';


const RouteComponent = ({handleLocationChange}) => {
  const location = useLocation();

  useEffect(() => {
    if(location) {
      handleLocationChange(location);
    }
  },[location]);

  return (
    <></>
  )
}


export default RouteComponent;