//ui state constants
export const IS_LOADING = 'ui/IS_LOADING';
export const LOAD_CATEGORIES = 'ui/LOAD_CATEGORIES';
export const LOAD_CATEGORIES_SEARCH = 'ui/LOAD_CATEGORIES_SEARCH';
export const LOAD_COUNTRIES = 'ui/LOAD_COUNTRIES';
export const SET_SELECTED_CATEGORY = 'ui/SET_SELECTED_CATEGORY';

//bulk product constants
export const LOAD_UPLOAD_HISTORY = 'bulkProducts/LOAD_UPLOAD_HISTORY';
export const RELOAD_UPLOAD_HISTORY = 'bulkProducts/RELOAD_UPLOAD_HISTORY';

//Add/Edit Single Product constants
export const SET_PRODUCT_DATA = 'product/SET_PRODUCT_DATA';