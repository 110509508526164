import { combineReducers } from 'redux';
import productReducer from 'screens/SupplierDashboard/NewAddEditSingleProduct/reducers';
import { IS_LOADING, LOAD_CATEGORIES, LOAD_COUNTRIES, LOAD_CATEGORIES_SEARCH,SET_SELECTED_CATEGORY } from 'constants/store';


const initialUIState = {
  loading: false,
  categories: [],
  categoriesSearch: [],
  countries: [],
  selectedCategories: {},
}

function uiReducer(state = initialUIState, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case LOAD_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case LOAD_CATEGORIES_SEARCH:
      return {
        ...state,
        categoriesSearch: action.categoriesSearch,
      };
    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategories: action.selectedCategories,
      };
    case LOAD_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
      };
    default:
      return state;
  }
}

const createReducers = () => {
  return combineReducers({
    ui: uiReducer,
    product: productReducer,
  });
};

export default createReducers;