import styled from 'styled-components';
import { MediaQ } from './utils';

export const Title = styled.h1`
    font-weight: ${props =>
      props.weight === 'bolder'
        ? 900
        : props.weight === 'bold'
        ? 700
        : props.weight === 'light'
        ? 300
        : 400};
    font-size: ${props =>
      props.size === 'larger'
        ? '2.25rem'
        : props.size === 'large'
        ? '2rem'
        : props.size === 'medium'
        ? '1.5rem'
        : '1.25rem'};
    color: ${props => (props.color ? props.color : '#222')};
    ${props => {
      if (props.color) {
        if (props.color === 'primary') {
          return `color: ${props.theme.colors.primary}`;
        }
        if (props.color === 'blue') {
          return `color: ${props.theme.colors.blue}`;
        }
      } else {
        return 'color: #2e2e2e';
      }
    }};
    text-transform: ${props =>
      props.textTransform === 'uppercase'
        ? 'uppercase'
        : props.textTransform === 'lowercase'
        ? 'lowercase'
        : 'initial'}¸
    line-height: 3rem;
    margin: 0 0 1rem;
    text-align: ${props => (props.align === 'center' ? props.align : 'left')};
    word-spacing: 2px;
    ${MediaQ('768px')`
        line-height: 60px;
        margin: 0;
    `};
    ${MediaQ('1441px')`
        line-height: 66px;
        margin: 0;
        font-size: ${props =>
          props.size === 'large'
            ? '2.25rem'
            : props.size === 'medium'
            ? '1.75rem'
            : '1.25rem'};
    `};
`;

export const Text = styled.p`
  line-height: ${props => (props.lheight ? props.lheight : '32px')};
  margin: ${props => (props.size === 'large' ? '1rem 0' : '0')};
  padding: 0;
  font-size: ${props => (props.size === 'large' ? '1.125rem' : '1rem')};
  font-weight: ${props =>
    props.weight === 'bold'
      ? 'bold'
      : props.weight === 'light'
      ? 'lighter'
      : 'normal'};
  width: ${props => (props.width ? props.width : 'initial')};
  color: ${props => (props.color ? props.color : '#222')};
  ${props => {
    if (props.color) {
      if (props.color === 'primary') {
        return `color: ${props.theme.colors.primary}`;
      }
      if (props.color === 'blue') {
        return `color: ${props.theme.colors.blue}`;
      }
    } else {
      return 'color: #2e2e2e';
    }
  }};
  ${MediaQ('768px')`
        line-height: 40px;
        font-size: ${props => (props.size === 'large' ? '1.125rem' : '16px')};
    `};
  ${MediaQ('1441px')`
        line-height: 42px;
        font-size: ${props => (props.size === 'large' ? '1.25rem' : '18px')};
    `};
`;
