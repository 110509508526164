import styled, { css } from "styled-components";
import { Flex, MediaQ } from "../../styled-components/utils";
import { Button } from "../../styled-components/buttons";

export const Navbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  background: ${props =>
    props.isActive || props.layout || props.isSpecialNavbar
      ? "white"
      : "rgba(0,0,0,0)"};
  width: 100%;
  height: ${props =>
    props.theme.layout.navbarHeight
      ? props.theme.layout.navbarHeight
      : "4.125rem"};
  margin: 0;
  padding: 0;
  z-index: 999;
  transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out;
  transform: translateZ(0);
  box-shadow: ${props =>
    props.layout ? "0 4px 16px 0 rgba(0, 0, 0, 0.08)" : "none"};
`;

export const Wrapper = styled.div`
  ${Flex("row nowrap", "center", "space-between")};
  position: relative;
  width: 100%;
  overflow: hidden;
  height: ${props =>
    props.theme.layout.navbarHeight
      ? props.theme.layout.navbarHeight
      : "4.125rem"};
`;

export const Brand = styled.div`
  ${Flex("row nowrap", "center", "center")};
  height: ${props =>
    props.theme.layout.navbarHeight
      ? props.theme.layout.navbarHeight
      : "4.125rem"};
  width: auto;
  ${MediaQ("1025px")`
        ${Flex("row nowrap", "center", "flex-start")};
        width: 200px;
    `}
`;

export const HomeLink = styled.a`
  ${Flex("row nowrap", "center", "center")};
  padding: 0;
  height: ${props =>
    props.theme.layout.navbarHeight
      ? props.theme.layout.navbarHeight
      : "4.125rem"};
  width: auto;
  cursor: pointer;
  padding: 4px;
  img {
    vertical-align: middle;
    height: 100%;
    width: auto;
  }
  p {
    font-size: 1.25rem;
    font-family: Georgia, "Bitstream Charter", "URW Bookman L", serif;
    letter-spacing: 0.25px;
    font-size: 1.35rem;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #2e2e2e;
  }
`;

export const Links = styled.ul`
  display: none;
  #signIn a {
    color: #2e2e2e;
  }

  @media screen and (min-width: 768px) {
    ${Flex("row nowrap", "center", "flex-end")};
    width: auto;
    border: none;
    background: transparent;
    li {
      line-height: ${props => props.theme.layout.navbarHeight};
      margin: 0 0.5rem;
    }
    #signIn {
      border: none;
      background: transparent;
      word-spacing: 2px;
      line-height: normal;
      height: 36px;
      min-width: 88px;
      display: block;
      cursor: pointer;
      font-weight: 500;
      padding: 0.5rem 1rem;
      text-align: center;
      border-radius: 25px;
    }

    #signIn a {
      color: #2e2e2e;
    }
    #bdSignUp a,
    #signUp a {
      color: #2e2e2e;
    }
    #aboutButton {
      background: ${props => props.theme.colors.primary};
      padding: 0 1rem;
      height: 36px;
      border-radius: 25px;
      color: white;
      line-height: 36px;
      a {
        color: white;
      }
    }
    #aboutUsButton {
      a {
        color: #2e2e2e;
      }
    }
  }

  @media screen and (min-width: 1025px) {
    li {
      margin: 0 1rem;
    }
  }
`;

export const Toggle = styled(Button)`
  ${Flex("column nowrap", "center", "center")};
  min-width: 42px;
  height: 42px;
  background: none;
  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
`;

export const NavbarLink = styled.li`
  list-style-type: none;
  padding: 0 15px;
  margin: 0 25px;
  height: ${props => props.theme.layout.navbarHeight};
  width: auto;
  cursor: pointer;
  line-height: 50px;
`;

export const Bar = styled.span`
  width: 32px;
  height: 4px;
  background-color: #ccc;
  margin: 3px 0;
  transition: 0.4s;
  border-radius: 3px;
  ${props => {
    if (props.isActive) {
      if (props.position === "top") {
        return css`
                    -webkit-transform: rotate(-45deg) translate(-7px, 5px);
                    rotate(-45deg) translate(-7px,5px)
                `;
      } else if (props.position === "middle") {
        return css`
          opacity: 0;
        `;
      } else {
        return css`
          -webkit-transform: rotate(45deg) translate(-9px, -7px);
          transform: rotate(45deg) translate(-9px, -7px);
        `;
      }
    }
  }}
`;

export const Menu = styled.div`
  position: absolute;
  top: 4px;
  right: 0;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const NavbarExpanded = styled.div`
  display: ${props => (props.isActive ? "block" : "none")};
  ${MediaQ("768px")`
      display: none;
    `};
`;

export const Overlay = styled.div`
  ${Flex("column nowrap", "flex-start", "flex-start")}
  position: fixed;
  top: 50px;
  left: 0;
  height: calc(100vh - 50px);
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1001;
`;

export const NavbarLinks = styled.ul`
  height: auto;
  width: 100%;
  display: block;
  padding: 0;
  margin: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
`;

export const ExpandedLink = styled.li`
  background: white;
  width: 100%;
  display: block;
  height: 50px;
  line-height: 50px;
  padding: 0 1rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  a {
    display: block;
    color: #2e2e2e;
  }
  &:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
`;

export const ExpandedButton = styled(Button)`
  background: none;
  border: none;
  color: #2e2e2e;
  text-align: left;
  line-height: 50px;
  font-size: 1rem;
  font-weight: normal;
  padding: 0;
`;

export const LinkButton = styled.a``;
