import * as React from "react";
import * as Sentry from "@sentry/browser";
import moment from 'moment';
import DOMPurify from 'dompurify';
import { Icon } from "../screens/Supplier/SupplierTestimonials/Testimonials/styles";
import "./modernizr.js";
import {BASE_URL, JSON_HEADER, IS_PRODUCTION} from 'constants/index';
import {
  metaLinksData,
  collectionLinksData,
  productDetailsData
} from "../data";

export const setValidToParent = e => {
  e.currentTarget.parentNode.classList.remove("invalid");
  e.currentTarget.parentNode.classList.add("valid");
};

export const setInvalidToParent = e => {
  e.currentTarget.parentNode.classList.remove("valid");
  e.currentTarget.parentNode.classList.add("invalid");
};

export const domPurifyCleanup = (item) => {
  const clean = DOMPurify.sanitize(item);
  return clean;
};

export const sanitizeTypes = (type,value) => {
  if(type === "string") {
    return domPurifyCleanup(value);
  } else if(type === "number") {
    return Number(domPurifyCleanup(value));
  } else if(type === "boolean") {
    return Boolean(domPurifyCleanup(value));
  }
};

export const sanitizeObject = (object) => {
  let cleanObj = {};
  for (const [key, value] of Object.entries(object)) {
    const type = typeof value;
    const constructorType = Object.getPrototypeOf(value).constructor;
    if(type === "string" || type === "number" || type === "boolean") {
      Object.assign(cleanObj, { ...cleanObj, [key]: sanitizeTypes(type,value) });
    } else if(type === "array" || (type === "object" && constructorType === Array)) {
      let tempArray = [];
      value.map(item => {
        const itemType = typeof item;
        if(itemType === "object") {
          tempArray.push(sanitizeObject(item));
        } else {
          tempArray.push(sanitizeTypes(itemType,item));
        }
      });
      Object.assign(cleanObj, { ...cleanObj, [key]: domPurifyCleanup(tempArray) });
    } else if(type === "object" || (type === "object" && constructorType === Object)) {
      Object.assign(cleanObj, { ...cleanObj, [key]: sanitizeObject(value) });
    } else {
      Object.assign(cleanObj, { ...cleanObj, [key]: value });
      console.log(type,key,value,"unhandled/undefined type in object fn");
    }
  }
  return cleanObj;
};

export const sanitizeStringifiedObject = (object) => {
  let cleanObject = {};
  let json = JSON.parse(object);
  if(Object.keys(json).length === 0 && json.constructor === Object) {
    cleanObject = JSON.stringify({});
  } else {
    for (const [key, value] of Object.entries(json)) {
      const type = typeof value;
      const constructorType = Object.getPrototypeOf(value).constructor;
      if(type === "string" || type === "number" || type === "boolean") {
        Object.assign(cleanObject, { ...cleanObject, [key]: sanitizeTypes(type,value) });
      } else if(type === "array" || (type === "object" && constructorType === Array)) {
        let tempArray = [];
        value.map(item => {
          const itemType = typeof item;
          if(itemType === "object") {
            tempArray.push(sanitizeObject(item));
          } else {
            tempArray.push(sanitizeTypes(itemType,item));
          }
        });
        Object.assign(cleanObject, { ...cleanObject, [key]: tempArray });
      } else if(type === "object" || (type === "object" && constructorType === Object)) {
        Object.assign(cleanObject, { ...cleanObject, [key]: sanitizeObject(value) });
      } else {
        Object.assign(cleanObject, { ...cleanObject, [key]: value });
        console.log(type,key,value,"unhandled/undefined type in main fn");
      }
    }
    cleanObject = JSON.stringify(cleanObject);
  }
  return cleanObject;
};

export const fetchData = async (url, options) => {
  // if(options?.method === "POST" && options?.body) {
  //   options.body = sanitizeStringifiedObject(options.body);
  // }
  if(options?.headers?.hasOwnProperty('Access-Control-Allow-Credentials')){
    options.credentials = "include";
  }
  try {
    let response = await fetch(url, {
      ...options
    });
    if(response.status === 401) {
      window.location.href='/';
    }
    let data = await response.json();
    return data;
  } catch (error) {
    // console.log(error,"error");
    return { status: "failure", message: "Failed to fetch!" };
  }
};

export const fetchFormData = async (url, options) => {
  try {
    let response = await fetch(url, {
      ...options,
      mode: 'cors',
    });
    if(response?.status === 401 && window.location.pathname === "/supplier-dashboard/orders-processing/upload-manifest") {
      window.location.href = "/supplier_dashboard";
    }
    let data = await response.json();
    return data;
  } catch (error) {
    // console.log(error,"error");
    return { status: "failure", message: "Failed to fetch!" };
  }
};

export const flashMessage = (ref, type, text) => {
  if (ref.current) {
    ref.current.innerHTML = text;
    if (type === "error") {
      ref.current.style.fontSize = "13px";
      ref.current.style.margin = "0.25rem 0";
      ref.current.style.color = "#bb0000";
    } else {
      ref.current.style.fontSize = "13px";
      ref.current.style.margin = "0.25rem 0";
      ref.current.style.color = "#4BB543";
    }
  }
};

export const handleNavigationInteraction = parent => {
  return new Promise((resolve, reject) => {
    if (parent) {
      parent.scrollIntoView({ behavior: "smooth" });
      resolve("scrolled!");
    } else {
      reject("failed!");
    }
  });
};

export const filterBy = (option, props) => {
  let { text: searchText } = props;
  if (/^\w*$/gi.test(searchText)) {
    let regex = new RegExp(`^${searchText}`, "gi");
    return regex.test(option) ? option : false;
  } else {
    return false;
  }
};

export const NextArrow = props => {
  const { className, onClick } = props;
  const next = React.useRef();

  return (
    <button
      className={className}
      ref={next}
      style={{
        display: "block",
        background: props.accentColor
          ? `${props.accentColor}!important`
          : "transparent"
      }}
      onClick={onClick}
    >
      <Icon src="https://d1ixo36kppfedg.cloudfront.net/web/images/home/arrow.png" direction="next" />
    </button>
  );
};

export const PrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{
        display: "block",
        background: props.accentColor
          ? `${props.accentColor}!important`
          : "transparent"
      }}
      onClick={onClick}
    >
      <Icon src="https://d1ixo36kppfedg.cloudfront.net/web/images/home/arrow.png" direction="previous" />
    </button>
  );
};

export const ContestNextArrow = props => {
  const { accentColor, onClick } = props;

  return (
    <button
      className="leaderboard-carousel-arrow leaderboard-carousel-next-arrow"
      style={{
        backgroundColor: accentColor ? `${accentColor}E6` : 'transparent',
      }}
      onClick={onClick}
    >
      <Icon src="https://d1ixo36kppfedg.cloudfront.net/web/images/icons/arrow-right-white.svg" direction="next" />
    </button>
  );
};

export const ContestPrevArrow = props => {
  const { accentColor, onClick } = props;
  return (
    <button
      className="leaderboard-carousel-arrow leaderboard-carousel-prev-arrow"
      style={{
        backgroundColor: accentColor ? `${accentColor}E6` : 'transparent',
      }}
      onClick={onClick}
    >
      <Icon
        src="https://d1ixo36kppfedg.cloudfront.net/web/images/icons/arrow-right-white.svg"
        direction="previous"
      />
    </button>
  );
};

export const resolveImages = (imagePath, isWebp) => {
  if (isWebp) {
    let stringArr = imagePath.split(".");
    let extension = stringArr[stringArr.length - 1];
    if (/(svg)$/.test(extension)) {
      return imagePath;
    } else {
      let regex = new RegExp("(" + extension + ")$", "g");
      return imagePath.replace(regex, "webp");
    }
  } else {
    return imagePath;
  }
};

export const categoryTemplate = {
  path: "collectionSection",
  collectionSection: "",
  objectId: "",
  objectType: ""
};

export const metaTemplate = {
  path: "META_COLLECTION_LIST",
  objectId: "",
  objectType: ""
};

export const getEncodedMetaLink = id => {
  let selectedLink = jsonArrayToBase64(metaLinksData).find(
    link => link.objectId == id
  );

  return selectedLink
    ? `https://glowroad.com/inApp/openLink?redirect=${selectedLink.encodedLink}`
    : "";
};

export const getEncodedCollectionProductLink = id => {
  let selectedLink = jsonArrayToBase64(collectionLinksData).find(
    link => link.objectId == id
  );

  return selectedLink
    ? `https://glowroad.com/inApp/openLink?redirect=${selectedLink.encodedLink}`
    : "";
};

export const getEncodedProductLink = id => {
  let selectedLink = jsonArrayToBase64(productDetailsData).find(
    link => link.objectId == id
  );

  return selectedLink
    ? `https://glowroad.com/inApp/openLink?redirect=${selectedLink.encodedLink}`
    : "";
};

// convert array of objects to base64
export const jsonArrayToBase64 = arr => {
  return arr.map(obj => {
    let json = JSON.stringify(obj);
    let encodedLink = window.btoa(json);
    return {
      ...obj,
      encodedLink
    };
  });
};

export const useAppDataHook = location => {
  if(location) {
    let params = location.search.slice(1).split("&");
    let appDataArray = params.map(el => {
      let [key, value] = el.split("=");
      let res = {};
      res.key = key;
      res.value = value;
      return res;
    });
    let appData = appDataArray.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }),
      {}
    );

    let defaultAppData = {
      user_auth_token: "",
      version: ""
    };
    Object.keys(appData).map(key => {
      if (key === "" || key === "undefined" || key === null) {
        delete appData[key];
      }
    });

    if(appData?.user_auth_token) {
      appData["user_auth_token"] = appData.user_auth_token;
    } else if(appData?.user_tmp_param1) {
      appData["user_auth_token"] = appData.user_tmp_param1;
    }

    return {
      ...defaultAppData,
      ...appData
    };
  }
};

export const useLocalStorage = () => {
  const storage = localStorage;

  const get = key => {
    return storage.getItem(key);
  };

  const set = (key, value) => {
    storage.setItem(key, value);
  };

  const getAll = () => {
    return Array.from({ length: storage.length }, (v, i) => i).map(index => ({
      key: storage.key(index),
      value: storage.getItem(storage.key(index)),
    }));
  };

  return {
    set,
    get,
    getAll,
  };
};

export const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(module => {
        resolve(module);
      })
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            Sentry.captureException(error);
            reject(error);
            return;
          }

          retry(fn, interval, retriesLeft - 1).then(resolve, reject);
        }, interval);
      });
  });
};

export const getRandomKey = async object => {
  let url = encodeURI(`${BASE_URL}/supplier/random`);
  let requestBody = JSON.stringify({...object});
  try {
    let response = await fetchData(url, {
      method: "POST",
      headers: JSON_HEADER,
      body: requestBody
    });
    return response;
  } catch (error) {
    if (IS_PRODUCTION) {
      Sentry.captureException(error);
    }
    return null;
  }
};

export const handleEncryption = (data, publicKey) => {
  if(window?.JSEncrypt) {
    const { JSEncrypt } = window;
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(publicKey, 'base64');
    return jsEncrypt.encrypt(data);
  }
}

export const getPage = pathname => {
  let page = '';
  let sidebar = false;
  let header = false;
  let showStaticHeader = false;
  if (/\/supplier-dashboard\/notice-board$/gi.test(pathname)) {
    page = 'notice board';
  } else if (/\/supplier-onboarding/gi.test(pathname)) {
    page = 'supplier onboarding';
    showStaticHeader =true;
    sidebar = true;
  } else if (/\/about/gi.test(pathname)) {
    page = 'about';
    showStaticHeader =true;
    sidebar = true;
  } else if (/\/supplier-dashboard\/supplier-bands$/gi.test(pathname)) {
    page = 'supplier bands';
    sidebar = true;
  } else if (/\/supplier-dashboard\/help-and-support$/gi.test(pathname)) {
    page = 'help and support';
  } else if (pathname.includes("supplier-dashboard/deals/participate")) {
    page = 'Add products to Deal';
    sidebar = true;
  } else if (pathname.includes("supplier-dashboard/deals/performance")) {
    page = 'Deal Performance';
    sidebar = true;
  } else if (/\/supplier-dashboard\/deals$/gi.test(pathname)) {
    page = 'deals';
  } else if (/\/supplier-dashboard\/advertisement$/gi.test(pathname)) {
    page = 'advertisement';
    sidebar = true;
  } else if (pathname.includes("supplier-dashboard/my-earnings")) {
    page = 'my earnings';
  } else if (/\/supplier-dashboard\/verification$/gi.test(pathname)) {
    page = 'otp verification';
    sidebar = true;
  } else if (/\/supplier-dashboard\/resetpassword$/gi.test(pathname)) {
    page = 'reset password';
    sidebar = true;
  } else if (/\/supplier-dashboard\/add-product$/gi.test(pathname)) {
    page = 'add product';
    sidebar = true;
  } else if (/\/supplier-dashboard\/shipping$/gi.test(pathname)) {
    page = 'shipping';
  } else if (/\/supplier-dashboard\/settings$/gi.test(pathname)) {
    page = 'settings';
  } else if (/\/supplier-dashboard\/product-listing$/gi.test(pathname)) {
    page = 'product listing';
  } else if (/\/supplier-dashboard\/collection-listing$/gi.test(pathname)) {
    page = 'collection listing';
  } else if (/\/supplier-dashboard\/add-single-product$/gi.test(pathname)) {
    page = 'add single product';
    sidebar = true;
  } else if (pathname.includes("/supplier-dashboard/edit-product")) {
    page = 'edit product';
    sidebar = true;
  } else if (pathname.includes("/supplier-dashboard/admin/edit-product")) {
    page = 'edit product';
    sidebar = true;
  } else if (/\/supplier-dashboard\/add-multiple-products$/gi.test(pathname)) {
    page = 'add multiple product';
    sidebar = true;
  } else if (/\/supplier-dashboard\/add-collection$/gi.test(pathname)) {
    page = 'add collection';
    sidebar = true;
  } else if (pathname.includes("/supplier-dashboard/edit-collection")) {
    page = 'edit collection';
    sidebar = true;
  } else if (/\/supplier-dashboard\/add-product-excel$/gi.test(pathname)) {
    page = 'add product excel';
  } else if (/\/supplier-dashboard\/add-inventory-excel$/gi.test(pathname)) {
    page = 'add inventory excel';
  } else if (/\/supplier-dashboard\/image-upload$/gi.test(pathname)) {
    page = 'image upload';
  } else if (/\/supplier-dashboard\/orders-listing\/manifested-orders$/gi.test(pathname)) {
    page = 'manifested orders';
  } else if (/\/supplier-dashboard\/orders-listing\/shipped-orders$/gi.test(pathname)) {
    page = 'shipped orders';
  } else if (/\/supplier-dashboard\/orders-listing\/delivered-orders$/gi.test(pathname)) {
    page = 'delivered orders';
  } else if (/\/supplier-dashboard\/orders-listing\/cancelled-orders$/gi.test(pathname)) {
    page = 'cancelled orders';
  } else if (/\/supplier-dashboard\/orders-listing\/return-without-rto-orders$/gi.test(pathname)) {
    page = 'returned orders';
  } else if (/\/supplier-dashboard\/orders-listing\/rto-orders$/gi.test(pathname)) {
    page = 'rto orders';
  } else if (/\/supplier-dashboard\/orders-processing\/new-orders-accept$/gi.test(pathname)) {
    page = 'accept new orders';
  } else if (/\/supplier-dashboard\/orders-processing\/new-orders-invoice$/gi.test(pathname)) {
    page = 'new orders invoice';
  } else if (/\/supplier-dashboard\/orders-processing\/upload-manifest$/gi.test(pathname)) {
    page = 'upload manifest';
  } else if (/\/supplier-dashboard\/unauthorized$/gi.test(pathname)) {
    page = 'unauthorized';
    sidebar = true;
    header = true;
  } else if (/\/supplier-dashboard/gi.test(pathname)) {
    page = 'supplier dashboard';
  } else if (/\/*/gi.test(pathname)) {
    if(pathname === '/how-to-earn') {
      page = 'home';
      showStaticHeader =true;
      sidebar = true;
    } else {
      page = 'Page 404';
      sidebar = true;
    }
    
  }
  console.log(page, sidebar, showStaticHeader);
  return {page, sidebar, header ,showStaticHeader};
};

export const setLastUsedCategory = (levelCategories) => {
  window.localStorage.setItem('lastUsedCategory',JSON.stringify(levelCategories));
}

export const getHumanReadableDate = (epochTimestamp) => {
  const date = new Date(epochTimestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const readableDate = day + "/" + month + "/" + year + " " + hours + ":" + minutes;
  return readableDate;
}

export const getHHMMSSTime = (epochTime) => {
  let epoch_time = epochTime * 1000;
  var date_obj = new Date(epoch_time);
  const hrs = date_obj.getHours();
  const mins = date_obj.getMinutes();
  const sec = date_obj.getSeconds();
  const time = (hrs < 10 ? "0" + hrs : hrs) + ":" + (mins < 10 ? "0" + mins : mins) + ":" + (sec < 10 ? "0" + sec : sec);
  return time;
}

export const getDDMonYYYYDate = (yyyymmdd) => {
  const date = yyyymmdd.trim();
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  // First check for the pattern
  if(!regex.test(date)) {
    return false;
  }
  const day = parseInt(date.substring(8, 10));
  const month = parseInt(date.substring(5, 7));
  const year = parseInt(date.substring(0, 4));
  if(month == 0 || month > 12) {
    return false;
  }
  if(year == 0) {
    return false;
  }
  var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
  // Adjust for leap years
  if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
    monthLength[1] = 29;
  }
  if(day == 0 || day > monthLength[month - 1]) {
    return false;
  }
  const monthNames = ["Jan", "Feb", "Mar", "Apr",
                      "May", "Jun", "Jul", "Aug",
                      "Sep", "Oct", "Nov", "Dec"];
  return day+' '+monthNames[month-1]+' '+year;
}

export const authenticationRedirectionHandler = (error) => {
  if(error?.response?.status === 401) {
    window.location.href = '/supplier-dashboard/unauthorized';
  } else {
    alert(error);
  }
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function deleteCookie(name) {
  document.cookie = `${name}=; Max-Age=0; path=/; domain=.glowroad.com`;
}

export function convertToCamelCase(string) {
  let str = string;
  str = str.replace(" ","");
  str = str.charAt(0).toLowerCase() + str.slice(1);
  return str
}

export function formatDate(date) {
  const dateString = date.toDateString();
  const dateSplit = dateString.split(" ");
  return `${dateSplit[2]} ${dateSplit[1]}`;
}

export function getDay(epoch) {
  const timeStamp24HourFormat = moment(epoch).format('DD MMM YYYY HH:mm');
  return timeStamp24HourFormat;
}

export function dealScheduleTime(epoch) {
  const timeStamp12HourFormat = moment(epoch).format('D MMM, h:mm A');
  return timeStamp12HourFormat;
}

export const getDealsCampaignDateTime = (epoch) => {
    return moment(epoch).format("YYYY-MM-DD HH:mm:ss");
}

export const getYYYYMMDD = (epoch) => {
  return moment(epoch).format("YYYY-MM-DD");
}

export const calculatePercentage = (percent, total) => {
  return ((percent/ 100) * total);
}


export function calculateDaysBetweenEpochs(startDate, endDate) {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  const differenceInMilliseconds = Math.abs(endDate - startDate);

  const differenceInDays = Math.round(differenceInMilliseconds / (1000 * 60 * 60 * 24));

  return differenceInDays;
}

export const getNextPreviousYears = (date) => {
  let currentYear = date.getFullYear();
  let nextYear = currentYear + 1;
  let prevYear = currentYear - 1;
  return [prevYear,currentYear,nextYear];
}

export const getLocaleText = (locale, text) => {
  const files = {
    en_IN: require("../localization/english"),
    hi_IN: require("../localization/hindi"),
  };
  return files[locale]?.default?.[text];
};