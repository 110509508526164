import { getCookie } from "utils";
export const JSON_HEADER = {
  "content-type": "application/json",
  "source": "app",
};

export const TEMP_JSON_HEADER = {
  "content-type": "application/json",
  "source": "app",
  "Access-Control-Allow-Credentials": "true",
}

const { NODE_ENV, API_BASE_URL } = process.env;

export const IS_PRODUCTION = NODE_ENV === "production" ? true : false;

export const SET_LEADERBOARD = "SET_LEADERBOARD";
export const GET_RANKINGS = "GET_RANKINGS";
export const INIT_TABLE = "INIT_TABLE";

//Base URL
// export const BASE_URL = "http://localhost:8080";
export const BASE_URL = "";
// export const SUPPLIER_BASE_URL = "https://testsupplier.glowroad.com";
export const SUPPLIER_BASE_URL = "https://supplier.glowroad.com";
// export const ADMIN_BASE_URL =  "https://test-it.glowroad.com";
export const ADMIN_BASE_URL =  "https://it.glowroad.com";
// export const GR_BASE_URL = "https://feed-devo-2.glowroad.com";
export const GR_BASE_URL =  "https://api.glowroad.com";


const getToken = () => {
  const {localStorage} = window;
  let header = {};
  let userAuthToken = "";
  let authToken = "";
  let jwtCookie = "";
  let proxyAuthToken = "";
  if(getCookie("userAuthToken")) {
    jwtCookie = getCookie("userAuthToken");
  }
  if(jwtCookie) {
    localStorage.setItem("userAuthToken",jwtCookie);
    localStorage.removeItem("authToken");
  }
  if(localStorage.getItem("userAuthToken")) {
    userAuthToken = localStorage.getItem("userAuthToken");
  }
  if(localStorage.getItem("authToken")) {
    authToken = localStorage.getItem("authToken");
  }
  if(localStorage.getItem("proxyAuthToken")) {
    proxyAuthToken = localStorage.getItem("proxyAuthToken");
  }
  // if(getCookie("userAuthToken")) {
  //   userAuthToken = getCookie("userAuthToken");
  // }
  // if(getCookie("proxyAuthToken")) {
  //   proxyAuthToken = getCookie("proxyAuthToken");
  // }
  if(userAuthToken && proxyAuthToken) {
    header = {...TEMP_JSON_HEADER,userAuthToken, proxyAuthToken};
  } else if(userAuthToken && !proxyAuthToken) {
    header = {...TEMP_JSON_HEADER,userAuthToken};
  } else if(authToken && proxyAuthToken) {
    header = {...TEMP_JSON_HEADER,authToken, proxyAuthToken};
  } else if(authToken && !proxyAuthToken) {
    header = {...TEMP_JSON_HEADER,authToken};
  } else {
    header = {...TEMP_JSON_HEADER};
  }
  return header;
}

const getWebViewToken = () => {
  const search = new URL(window.location.href).searchParams;

  return {
    ...JSON_HEADER,
    token: search.get("user_auth_token") || search.get("user_tmp_param1"),
  };
};

export const SUPPLIER_JSON_HEADER = getToken();
export const GR_JSON_HEADER = getWebViewToken();