import React from 'react';


const NewLoader = () => {
  
  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 bg-blackLOpacity z-999'>
        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          <div className='w-16 h-16 border-4 border-blue-400 border-r-transparent border-dotted rounded-full animate-spin'></div>
        </div>
    </div>
  )
}

export default NewLoader;