import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import createReducers from 'store/reducers';
import { SUPPLIER_BASE_URL, SUPPLIER_JSON_HEADER } from 'constants/index';

export default function configureStore(initialState) {
  const axiosInstance = axios.create({
    baseURL: SUPPLIER_BASE_URL,
    headers: SUPPLIER_JSON_HEADER
  })
 const state = initialState;
 const store = createStore(createReducers(), state, applyMiddleware(thunk.withExtraArgument(axiosInstance)));
 return store;
}