import * as React from "react";
import { Container } from "../../styled-components/container";
import Portal from "../../components/Portal";
import LoginModal from "./LoginModal";
import {
  Navbar,
  Brand,
  Menu,
  Wrapper,
  Links,
  HomeLink,
  NavbarExpanded,
  NavbarLinks,
  ExpandedLink,
  Overlay,
  Bar,
  Toggle,
  ExpandedButton,
  LinkButton
} from "./styles";

const Header = React.forwardRef((props, ref) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isFixed, setIsFixed] = React.useState(false);

  React.useEffect(() => {
    setIsFixed(props.isFixed);
  }, [props.isFixed]);

  const closeModal = () => {
    // check if window position is greater that 0
    setIsOpen(false);
  };

  const openModal = e => {
    e.preventDefault();
    setShowMenu(false);
    setIsOpen(true);
  };

  const handleNavigation = e => {
    setShowMenu(false);
    props.handleNavigation();
  };

  const toggleNavbar = () => {
    if (showMenu) {
      if (window.pageYOffset > 5) {
        props.fixNavbar();
      } else {
        setIsFixed(false);
      }
    }
    setShowMenu(!showMenu);
  };

  const goToPage = url => {
    setShowMenu(false);
    window.location.href = url;
  };

  return (
    <>
      <Navbar
        ref={ref}
        isActive={showMenu}
        layout={isFixed}
        isSpecialNavbar={props.isSpecialNavbar}
        id="mainHeader"
      >
        <Container>
          <Wrapper>
            <Brand>
              <HomeLink href="/">
                <img src="https://d1ixo36kppfedg.cloudfront.net/web/images/footer/logo-2.svg" alt="glowroad logo" />
              </HomeLink>
              <HomeLink href="/">
                <p>GlowRoad</p>
              </HomeLink>
            </Brand>

            <Links>
              <li
                id="aboutUsButton"
                className="button"
                onClick={() => goToPage("/about")}
                style={{ cursor: "pointer" }}
              >
                <a>About Us</a>
              </li>
              <li
                id="aboutButton"
                className="button"
                onClick={handleNavigation}
                style={{ cursor: "pointer" }}
              >
                <a>Become a Supplier</a>
              </li>
              <li id="signIn" className="button" onClick={openModal}>
                <LinkButton>Supplier Login</LinkButton>
              </li>
            </Links>

            <Menu>
              <Toggle onClick={toggleNavbar}>
                <Bar className="bar1" position="top" isActive={showMenu} />
                <Bar className="bar2" position="middle" isActive={showMenu} />
                <Bar className="bar3" position="bottom" isActive={showMenu} />
              </Toggle>
            </Menu>
            <Portal
              styles={{
                maxWidth: "90%",
                maxHeight: "550px",
                minHeight: "350px"
              }}
              onClose={closeModal}
              isOpen={isOpen}
            >
              <LoginModal triggerClose={closeModal} />
            </Portal>
          </Wrapper>
        </Container>
      </Navbar>
      <NavbarExpanded isActive={showMenu}>
        <Overlay>
          <NavbarLinks>
            <ExpandedLink>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => goToPage("/about")}
              >
                About Us
              </a>
            </ExpandedLink>
            <ExpandedLink>
              <a style={{ cursor: "pointer" }} onClick={handleNavigation}>
                Become a Supplier
              </a>
            </ExpandedLink>
            <ExpandedLink onClick={openModal}>
              <ExpandedButton as="a">Supplier Login</ExpandedButton>
            </ExpandedLink>
          </NavbarLinks>
        </Overlay>
      </NavbarExpanded>
    </>
  );
});

Header.defaultProps = {};

export default Header;
