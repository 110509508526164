const englishLocale = {
  RECHARGE_STATUS: 'Recharge Status',
  AWAITING_RESPONSE: 'Awaiting Payment Confirmation',
  DELETE_ACCOUNT: 'Delete GlowRoad Account',
  DELETE_ACCOUNT_QUESTION: 'Why would you like to delete your account?',
  DELETE_ACCOUNT_REASONS: [
    { text: 'I don’t want to use GlowRoad anymore' },
    { text: 'I’m using a different account' },
    { text: 'I want to create a new account' },
    { text: 'I have privacy concerns' },
    { text: 'Others' },
  ],
  DELETE_ACCOUNT_CONTINUE: 'Continue',
  DELETE_ACCOUNT_CONSENT_HEADING: 'You’re about to delete your account.',
  DELETE_ACCOUNT_CONSENT_MAIN:
    'You are about to submit a request for us to permanently delete your GlowRoad account and delete your data. All the data associated with it (including your profile, photos, reviews and subscriptions) will be permanently deleted in 40 days. This information can’t be recovered once the account is deleted.',
  DELETE_ACCOUNT_CONSENT_BLOCK:
    'Please note deleting your account is a permanent action. Once your account is deleted it will no longer be available to you and cannot be restored. If you decide later that you want to start ordering from us again or if you would like to use products and services that require an account, you will need to create a new account.',
  DELETE_ACCOUNT_CONSENT_NOTE:
    'Please be advised that GlowRoad is legally required or entitled to retain some types of data, such as order history. We do this in line with applicable laws including for tax and accounting and fraud prevention purposes.',
  DELETE_ACCOUNT_CONSENT_CONFIRM:
    'Yes, I want to permanently delete my GlowRoad account and delete my data.',
  DELETE_ACCOUNT_DELETE_MY_ACCOUNT: 'Delete My Account',
};

export default englishLocale;
