import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import {
  Wrapper,
  LoginBanner,
  LoginHeading,
  LoginBody,
  HeadingWrapper,
  ImageWrapper,
  LoginForm,
  SignUpWrapper,
  ForgotPassword,
  LoginInput,
  Icon
} from "./styles";
import { Button } from "styled-components/buttons";
import {
  FormGroup,
  ValidationError
} from "styled-components/form-elements";
import { Text } from "styled-components/typography";
import { fetchData, resolveImages, getRandomKey, handleEncryption } from "utils";
import { BASE_URL, IS_PRODUCTION, JSON_HEADER } from "constants/index";
import { AppContext } from 'index';
import { validatePassword } from "utils/validation";

const supplierLogin = async (encrypted,userId) => {
  let url = encodeURI(`${BASE_URL}/user/supplierlogin`);
  let requestBody = JSON.stringify({md: encrypted, userId });
  try {
    let response = await fetchData(url, {
      method: "POST",
      headers: JSON_HEADER,
      body: requestBody
    });
    return response;
  } catch (error) {
    if (IS_PRODUCTION) {
      Sentry.captureException(error);
    }
    return null;
  }
};

const supplierForgotPassword = async encrypted => {
  let url = encodeURI(`${BASE_URL}/user/supplierforgotpassword`);
  let requestBody = JSON.stringify({md:encrypted});
  try {
    let response = await fetchData(url, {
      method: "POST",
      headers: JSON_HEADER,
      body: requestBody,
    });
    return response;
  } catch (error) {
    if (IS_PRODUCTION) {
      Sentry.captureException(error);
    }
    return null;
  }
};

// const key = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAt55NFn1gOr3lqbZL+m/6VKOLobW9Jhjdm+/d1NmRg18t/D0OB8cxNvnz022GqSiPgSdldPVpLXgkXPOwIQ/8lLhRwDTpF3+P5B6UmpxlQxlOEv0m+q90QgIeXGAa3VaaD8QUARWPldAyjkDjdJiMOF2T1C64bTS0jH9eSWFjogk6Iljon57LykTuW4Q4SWwf9XEhsvMTpKk2Wo77ipsx0ecF+rmdutzwBqmD8QEdwIC7HXcZdpio0b98Sm2g39ua/Dil45y3x2Lv3RmaTav+CtDWaj6k/sdwDvpnPtf2hufOo4qQ68mSSjLrGB1ER+h71oI1xfo7C4h1ZvRTPrGl6wIDAQAB";

const LoginModal = props => {
  const [{isWebp,pwChange},dispatchContext] = useContext(AppContext);
  const [reset, setReset] = useState(false);
  const [failed, setFailed] = useState(false);
  const [mobileScreen, setMobileScreen] = useState(false);
  const [loginMsg, setLoginMsg] = useState("");
  const [resetMsg, setResetMsg] = useState("");
  const history = useHistory();
  const { triggerClose } = props; 

  const useForm = (handleSupplierLogin, handleSupplierForgotPassword, validate) => {
    const [values, setValues] = useState({ email: "", password: "" });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isResetting, setIsResetting] = useState(false);

    useEffect(
      () => {
        if (Object.keys(errors).length === 0) {
          if (isSubmitting) {
            let data = {};
            if(validatePassword(values.password)) {
              dispatchContext({ type: "VALID_PW" });
              data = {d: values.password, r: false};
            } else {
              dispatchContext({ type: "INVALID_PW" });
              data = {d: values.password, r: true};
            }
            getRandomKey({userId: -1}).then(res => {
              if(res?.random) {
                const emailEncryption = handleEncryption(JSON.stringify({email:values.email}), res.random);
                if(emailEncryption) {
                  getRandomKey({md:emailEncryption}).then(response => {
                    if(response?.random) {
                      const encrypted = handleEncryption(JSON.stringify({...data}), response.random);
                      if(encrypted) {
                        dispatchContext({ type: "SET_EMAIL", email: values.email });
                        handleSupplierLogin(encrypted,response?.userId);
                      } else {
                        console.log(encrypted);
                        alert("Encryption failed");
                      }
                    }
                  });
                } else {
                  alert("Email encryption failed")
                }
              }
            });
          }
          if (isResetting) {
            handleSupplierForgotPassword(values);
          }
        }
      },
      [errors]
    );

    useEffect(() => {
      if (window.innerWidth <= 425) {
        setMobileScreen(true);
      }
      const script = document.createElement("script");
      script.src = "./react-images/jsencrypt.min.js";
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
    }, []);

    const openReset = () => {
      setReset(true);
      setErrors({});
      setIsSubmitting(false);
    };

    const handleChange = e => {
      e.persist();
      e.target.parentElement.classList.add("valid");
      e.target.parentElement.classList.remove("invalid");
      setLoginMsg("");
      setValues(values => ({
        ...values,
        [e.target.name]: e.target.value
      }));
    };

    const handleLoginSubmit = e => {
      if (e) e.preventDefault();
      setErrors(validate(e, values));
      setIsSubmitting(true);
    };

    const handleResetSubmit = e => {
      if (e) e.preventDefault();
      setErrors(validate(e, values));
      setIsResetting(true);
    };

    return {
      openReset,
      handleChange,
      handleLoginSubmit,
      handleResetSubmit,
      values,
      errors
    };
  };

  const validate = (e, values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "Email address is required";
      e.target.children[0].classList.add("invalid");
      e.target.children[0].classList.remove("valid");
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email address is invalid";
      e.target.children[0].classList.add("invalid");
      e.target.children[0].classList.remove("valid");
    }
    if (!values.password && !reset) {
      errors.password = "Password is required";
      e.target.children[1].classList.add("invalid");
      e.target.children[1].classList.remove("valid");
    }

    return errors;
  };

  const handleSupplierLogin = (encrypted,userId) => {
    if(userId) {
      window.localStorage.setItem("userId", userId);
      supplierLogin(encrypted,userId).then(response => {
        processLoginResponse(response);
      });
    }
  }

  const handleSupplierForgotPassword = (data) => {
    getRandomKey({userId: -1}).then(res => {
      if(res?.random) {
        const encrypted = handleEncryption(JSON.stringify({email:data.email}), res.random);
        supplierForgotPassword(encrypted).then(response => {
          processResetResponse(response);
        });
      }
    });
  }

  const processLoginResponse = response => {
   const {status, message,showForgotPassword,redirecturl,userAuthToken,authToken} = response;
    if (status === "success") {
      if (redirecturl) {
        setFailed(false);
        if(redirecturl === "/supplier-dashboard/verification") {
          history.push(redirecturl);
        } else {
          if(userAuthToken) {
            window.localStorage.setItem("userAuthToken", userAuthToken);
            // document.cookie = `userAuthToken=${userAuthToken}`;
          }
          window.location.href = redirecturl;
        }
        setLoginMsg(message);
        setTimeout(() => {
          setLoginMsg("");
        }, 2000);
        setTimeout(() => {
          triggerClose();
        }, 2000);
      } else if (showForgotPassword) {
        openReset();
      } else {
        setLoginMsg(message);
        setFailed(true);
        setTimeout(() => {
          setLoginMsg("");
        }, 2000);
      }
    } else {
      setFailed(true);
      setLoginMsg(message);
    }
  };

  const processResetResponse = response => {
    const {status,message} = response;
    if (status === "success") {
      setResetMsg(message);
      setTimeout(() => {
        setResetMsg(false);
      }, 2000);
    } else {
      setResetMsg(message);
    }
  };

  const {
    values,
    errors,
    openReset,
    handleChange,
    handleLoginSubmit,
    handleResetSubmit
  } = useForm(handleSupplierLogin, handleSupplierForgotPassword, validate);

  // const handleClick = e => {
  //   if (e.currentTarget.children.length > 0) {
  //     e.currentTarget.children[0].click();
  //   }
  // };

  return (
    <Wrapper>
        <>
          <LoginBanner>
            {reset ? (
              <HeadingWrapper>
                <LoginHeading>Forgot Password?</LoginHeading>
                <ImageWrapper>
                  <Icon src={resolveImages("https://d1ixo36kppfedg.cloudfront.net/web/images/home/login1.png",isWebp)} alt={`money logo`} />
                </ImageWrapper>
              </HeadingWrapper>
            ) : (
              <HeadingWrapper>
                <LoginHeading>Login</LoginHeading>

                <ImageWrapper>
                  <Icon src={resolveImages("https://d1ixo36kppfedg.cloudfront.net/web/images/home/login1.png",isWebp)} alt="" />
                </ImageWrapper>
              </HeadingWrapper>
            )}
          </LoginBanner>
          <LoginBody>
            {reset ? (
              <LoginForm onSubmit={handleResetSubmit}>
                <FormGroup>
                  <LoginInput
                    type="email"
                    spellCheck={false}
                    name="email"
                    value={values.email}
                    placeholder={
                      mobileScreen
                        ? "Enter E-mail"
                        : "Enter your registered E-mail"
                    }
                    onChange={handleChange}
                  />
                  <ValidationError>{errors.email}</ValidationError>
                </FormGroup>
                <FormGroup>
                  {resetMsg && (
                    <Text
                      style={{
                        marginBottom: "1rem",
                        color: failed ? "#ff0000" : "#008000",
                        textAlign: "center"
                      }}
                    >
                      {resetMsg}
                    </Text>
                  )}
                  <Button
                    size="large"
                    color="primary"
                    radius="0px"
                    type="submit"
                  >
                    Reset
                  </Button>
                </FormGroup>
                <SignUpWrapper>
                  New at GlowRoad?{" "}
                  <a href="/supplier-onboarding">
                    <span>Sign Up!</span>
                  </a>
                </SignUpWrapper>
              </LoginForm>
            ) : (
              <LoginForm onSubmit={handleLoginSubmit} noValidate>
                <FormGroup>
                  <LoginInput
                    type="email"
                    name="email"
                    spellCheck={false}
                    value={values.email}
                    placeholder={
                      mobileScreen
                        ? "Enter E-mail"
                        : "Enter your registered E-mail"
                    }
                    onChange={handleChange}
                  />
                  <ValidationError>{errors.email}</ValidationError>
                </FormGroup>
                <FormGroup>
                  <LoginInput
                    type="password"
                    name="password"
                    spellCheck={false}
                    value={values.password}
                    placeholder="Password"
                    onChange={handleChange}
                    required
                  />
                  <ValidationError>{errors.password}</ValidationError>
                  <ForgotPassword onClick={openReset}>
                    Forgot Password?
                  </ForgotPassword>
                </FormGroup>
                <FormGroup>
                  {loginMsg && (
                    <Text
                      style={{
                        marginBottom: "1rem",
                        color: failed ? "#ff0000" : "#008000",
                        textAlign: "center"
                      }}
                    >
                      {loginMsg}
                    </Text>
                  )}
                  <Button
                    size="large"
                    color="primary"
                    radius="0px"
                    type="submit"
                  >
                    Login
                  </Button>
                </FormGroup>
                <SignUpWrapper>
                  New at GlowRoad?{" "}
                  <a href="/supplier-onboarding">
                    <span>Sign Up!</span>
                  </a>
                </SignUpWrapper>
              </LoginForm>
            )}
          </LoginBody>
        </>
    </Wrapper>
  );
};

export default LoginModal;
